import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux';

const EmailTemplate = (props) => {
    const emailTemplates = useSelector((state) => state.notification.emailTemplates);
    let history = useHistory();
    
    useEffect(() => {

    }, []);


    const loadTemplate = (templateId) => {
        history.push('/notification/email-templates/' + templateId)
    }


    return (
        <div>

            <ul>
                {
                    emailTemplates.map((template) => {
                        return <li onClick={() => loadTemplate(template.id)}>{template.name}</li>
                    })
                }
            </ul>
        </div>
    )
}

export default EmailTemplate;