import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination'
// import { compareValues } from '../../utils/utility';

const compareValues = (key, order = 'asc') => {
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
        let comparison = -1;
        if (a[key] && b[key]) {
            comparison = a[key].localeCompare(b[key]);
        }

        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%'
    },
    table: {
        minWidth: 650,
    },
});


export default function TableWithPagination(props) {
    const classes = useStyles();

    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [page, setPage] = React.useState(0)
    const [activeSort, setActiveSort] = React.useState('name');
    const [order, setOrder] = React.useState('asc');
    const [paidThruList, setPaidThruList] = React.useState([]);

    const columns = [

        { id: 'name', label: 'Name', enableSort: true },

        { id: 'email', label: 'Email', enableSort: true },
        { id: 'gender', label: 'Gender', enableSort: true },
        { id: 'dateOfBirth', label: 'DOB', enableSort: true },
        { id: 'clientName', label: 'Channel', enableSort: true },
        { id: 'benefitBegin', label: 'Benefit Begin', enableSort: true },
        { id: 'premiumPaidDate', label: 'Paid thru', enableSort: true }
    ]

    React.useEffect(() => {
        let list = [...props.paidThruList]
        if (list.length > 0) {
            list.sort(compareValues('name', 'asc'));
        }
        setPaidThruList(list)
        setPage(0)
    }, [props.paidThruList]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value)
        setPage(0)
    }




    const createSortHandler = (type) => {

        console.log(type);
        let sortedList = props.paidThruList.slice(0);

        let _order = order == 'asc' ? 'desc' : 'asc';
        if (activeSort != type) {
            _order = 'asc';
        }
        setOrder(_order);

        // console.log(sortedList)
        setActiveSort(type)

        sortedList.sort(compareValues(type, _order));


        setPaidThruList(sortedList)
        // console.log(sortedList)
    }

    let rows = paidThruList;

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div style={{
                    maxHeight: 'calc(100vh - 275px)',
                    overflow: 'auto'
                }}>

                    <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>

                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        style={{ maxWidth: 170, wordBreak: 'break-all' }}
                                    >
                                        {
                                            column.enableSort ?
                                                <TableSortLabel
                                                    active={activeSort == column.id}
                                                    direction={order}
                                                    onClick={() => createSortHandler(column.id)}
                                                >{column.label} </TableSortLabel> :
                                                <>{column.label}</>
                                        }

                                    </TableCell>
                                ))}


                            </TableRow>
                        </TableHead>
                        {
                            rows.length > 0 ?

                                <TableBody>



                                    {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        return (
                                            <TableRow >

                                                <TableCell className="paidthrou-cell">{row.name}</TableCell>
                                                <TableCell className="paidthrou-cell">{row.email}</TableCell>
                                                <TableCell className="paidthrou-cell">{row.gender == 'M' ? 'Male' : 'Female'}</TableCell>
                                                {/* <TableCell>Subscriber</TableCell> */}
                                                <TableCell className="paidthrou-cell">{row.dateOfBirth}</TableCell>
                                                <TableCell className="paidthrou-cell">{row.clientName}</TableCell>
                                                {/* <TableCell>Active</TableCell> */}
                                                <TableCell className="paidthrou-cell">{row.benefitBegin}</TableCell>
                                                <TableCell className="paidthrou-cell">{row.premiumPaidDate}</TableCell>

                                            </TableRow>
                                        )
                                    }
                                    )}



                                </TableBody> :

                                (

                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Data Not Found</TableCell>
                                        </TableRow>
                                    </TableBody>
                                )

                        }

                    </Table>

                </div>
                <TablePagination
                    hidden={props.isLoading || rows.length <= 0}
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    className={'table-pagination'}
                />
            </Paper>
        </div>
    );


}