import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { FormControl, InputLabel } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import styles from './notification.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { validateEmail } from '../../../helper/utils'

export default function TestEmail(props) {
    const [open, setOpen] = useState(false);
    const [emailValue, setEmail] = useState('');
    const [emailList, setEmailList] = useState([])
    useEffect(() => {
        console.log('pros.open::', props.open)
        setOpen(props.open)
    }, [props.open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose()
    };
    const sendEmail = () => {
        props.sendTestEmail(emailList)
    };

    const setEmailValue = (event) => {
        let value = event.target.value;
        setEmail(value)
    }

    const addToEmailList = () => {
        let _emailList = [...emailList];
        _emailList.push(emailValue)
        setEmailList(_emailList);
        setEmail('')
    }

    const deleteEmail = (index) => {
        let _emailList = [...emailList];
        _emailList.splice(index, 1);
        setEmailList(_emailList);
        // setEmail('')
    }
    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
            >
                {/* <DialogTitle disableTypography={true} id="alert-dialog-title">{"Alert"}</DialogTitle> */}
                <DialogContent>
                    <div className={styles.testEmail} >
                        <p>Add Email for Sending Test Email</p>

                        <div>
                            <FormControl variant="outlined" style={{
                                width: '100%'
                            }}>
                                <InputLabel htmlFor="get-email-input">Enter Email</InputLabel>
                                <OutlinedInput
                                    id="get-email-input"
                                    style={{ width: '100%' }}
                                    value={emailValue}
                                    onChange={setEmailValue}

                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                disabled={
                                                    !validateEmail(emailValue)
                                                }
                                                aria-label="toggle password visibility"
                                                onClick={addToEmailList}
                                                // onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    // label={'Enter Email'}
                                    labelWidth={100}
                                />
                            </FormControl>

                        </div>

                        <div className={styles.emailList}>
                            {
                                emailList.map((email, index) => {
                                    return (
                                        <div key={email} className={styles.email}>
                                            <span >{email}</span>
                                            <RemoveCircleOutlineIcon
                                                onClick={() => {
                                                    deleteEmail(index)
                                                }}
                                                style={{
                                                    color: '#a20a0a',
                                                    marginLeft: '5px',
                                                    cursor: 'pointer'
                                                }} />
                                        </div>
                                    )
                                })
                            }
                        </div>

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary" contained style={{
                        textTransform: 'none'
                    }}>
                        Cancel
                    </Button>
                    <Button onClick={sendEmail} color="primary"
                        disabled={emailList.length <= 0}
                        contained style={{
                            textTransform: 'none'
                        }}>
                        Send Email Now
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
