import React, { useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
// import Checkbox from '@material-ui/core/Checkbox';
import Radio from "@material-ui/core/Radio";
import { Checkbox, Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';

const RefundAmountSplitup = (props) => {
  const [shareAmt, setShareAmt] = useState('');
  const [shareAmtNotValid, setShareAmtNotValid] = useState(false)
  const [applicationFeeChecked, checkApplicationFee] = useState(false);
  const [creditCardFeeChecked, checkCreditCardFee] = useState(false);
  const [membershipDuesChecked, checkMembershipDues] = useState(false);
  const [shareContributionChecked, checkShareContribution] = useState(false);
  const [partialShareContributionChecked, checkPartialShareContribution] = useState(false);
  const [healthToolFeeChecked, checkHeathToolFee] = useState(false);
  const [rxSimpleFeeChecked, checkRxSimpleFee] = useState(false);
  const [uhfMonthlyFeeChecked, checkUhfMonthlyFee] = useState(false);
  const [totalAmtChcked, checkTotalAmnt] = useState(false);
  const [amountBreakup, setAmountBreakup] = useState({
    applicationFee: 0.0,
    monthlyShare: 0.0,
    uhfMonthlyFee: 0.0,
    healthToolAmount: 0.0,
    rxsimpleShareAmount: 0.0,
    totalAmountRefund: 0.0,
    creditCardFee: 0.0,
    transactionAmount: 0.0
  });

  useEffect(() => {
    let _amountBreakup = props.amountBreakup;
    console.log("amt---", props.amountBreakup);
    if (_amountBreakup) {
      _amountBreakup.applicationFee = parseFloat(
        _amountBreakup.applicationFee
      ).toFixed(2);
      _amountBreakup.monthlyShare = parseFloat(
        _amountBreakup.monthlyShare
      ).toFixed(2);
      _amountBreakup.uhfMonthlyFee = parseFloat(
        _amountBreakup.uhfMonthlyFee
      ).toFixed(2);
      _amountBreakup.healthToolAmount = parseFloat(
        _amountBreakup.healthToolAmount
      ).toFixed(2);
      _amountBreakup.rxsimpleShareAmount = parseFloat(
        _amountBreakup.rxsimpleShareAmount
      ).toFixed(2);
      _amountBreakup.totalAmountRefund = parseFloat(
        _amountBreakup.totalAmountRefund
      ).toFixed(2);
      _amountBreakup.creditCardFee = parseFloat(
        _amountBreakup.creditCardFee
      ).toFixed(2);
      _amountBreakup.transactionAmount = parseFloat(
        _amountBreakup.transactionAmount
      ).toFixed(2);
    }
    setAmountBreakup(_amountBreakup);
    // updateAmtBreakupDetails();
  }, [props.amountBreakup]);

  useEffect(() => {
    updateAmtBreakupDetails();

    console.log("updateAmtBreakupDetails");
  }, [
    applicationFeeChecked,
    totalAmtChcked,
    healthToolFeeChecked,
    rxSimpleFeeChecked,
    props.selectedPaymentMode,
    props.paymentType,
    amountBreakup,
    membershipDuesChecked,
    shareContributionChecked,
    partialShareContributionChecked,
    creditCardFeeChecked,
    shareAmt
  ]);

  const updateAmtBreakupDetails = () => {
    let totalRefundAmount = 0

    let feeType = applicationFeeChecked && !rxSimpleFeeChecked && !healthToolFeeChecked
      ? "ApplicationFee"
      : healthToolFeeChecked && !applicationFeeChecked && !rxSimpleFeeChecked
      ? "HealthToolFee"
      : rxSimpleFeeChecked && !healthToolFeeChecked && !applicationFeeChecked
      ? "RxSimplaShareFee"
      :applicationFeeChecked && healthToolFeeChecked ? 
      "ApplicationHealthtool"
      : applicationFeeChecked && rxSimpleFeeChecked 
      ?"ApplicationRxSimpleShare"
      :applicationFeeChecked && healthToolFeeChecked && rxSimpleFeeChecked ?
      "ApplicationHealthRxsimple"
      :healthToolFeeChecked && rxSimpleFeeChecked 
      ?
      "HealthToolRxSimpleshare"
      :
      "TotalAmount";

      totalRefundAmount = (applicationFeeChecked ? parseFloat(amountBreakup.applicationFee) : 0.00) +
      (creditCardFeeChecked ? parseFloat(amountBreakup.creditCardFee) : 0.00) +
      (membershipDuesChecked ? parseFloat(amountBreakup.uhfMonthlyFee) : 0.00) +
      (shareContributionChecked ? parseFloat(amountBreakup.monthlyShare) : shareAmt ? parseFloat(shareAmt) : 0.00)

      let newAmt = {
        appFee:  parseFloat(amountBreakup.applicationFee) || 0.00,
        monthlyFee:  parseFloat(amountBreakup.monthlyShare) || 0.00,
        monthlyShare:  parseFloat(amountBreakup.monthlyShare) || 0.00,
        creditCard:  parseFloat(amountBreakup.creditCardFee) || 0.00,
        total:  amountBreakup.totalAmountRefund || 0.00,
        // refundAmount: totalRefundAmount,
        refundAmount: amountBreakup.transactionAmount || 0.00,
      }

      props.updateRefundAmt(newAmt)
console.log('-----00------- , ', newAmt, ' -- ', shareAmt);
    props.updateAmountBreakUp(feeType);
  };

  const selectAmount = (type) => {
    switch (type) {
      case 'applicationFee':
        checkApplicationFee(!applicationFeeChecked);
        break;
      case 'membershipDues':
        checkMembershipDues(!membershipDuesChecked);
        break;
      case 'shareContribution':
        checkShareContribution(!shareContributionChecked);
        checkPartialShareContribution(shareContributionChecked && false);
        break;
      case 'partialShareContribution':
        checkPartialShareContribution(!partialShareContributionChecked);
        checkShareContribution(partialShareContributionChecked && false);
        break;
      case 'creditCardFee':
        checkCreditCardFee(!creditCardFeeChecked);
        break;
    }
  }

  useEffect(() => {
    if (props.paymentNumber == 0) {
      if (applicationFeeChecked) checkTotalAmnt(true);
      else checkTotalAmnt(false); 
    } else {
      if ((amountBreakup.applicationFee == 0.00 || (amountBreakup.applicationFee != 0.00 && applicationFeeChecked)) && membershipDuesChecked && shareContributionChecked) checkTotalAmnt(true);
      else checkTotalAmnt(false);
    }

    if (partialShareContributionChecked || applicationFeeChecked || membershipDuesChecked || shareContributionChecked) {
      if (partialShareContributionChecked) {
        if (shareAmt != '') props.setEnable(true);
        else props.setEnable(false);
      } else {
        if (applicationFeeChecked || membershipDuesChecked || shareContributionChecked) props.setEnable(true);
        else props.setEnable(false);
      }
    } else {
      props.setEnable(false);
    }

  }, [applicationFeeChecked, membershipDuesChecked, shareContributionChecked, shareAmt])

  const setShareValue = (e) => {
    if (parseFloat(amountBreakup.monthlyShare) < parseFloat(e)) {
      setShareAmtNotValid(true)
    } else {
      setShareAmtNotValid(false)
    }
    setShareAmt(e)
  }

  const checkTotal = () => {
    checkTotalAmnt(!totalAmtChcked);
    checkMembershipDues(!totalAmtChcked);
    checkPartialShareContribution(false)
    setShareValue('')
    checkShareContribution(!totalAmtChcked);
    if (amountBreakup.applicationFee != 0.00) checkApplicationFee(!totalAmtChcked);
  }

  if (amountBreakup && props.showAmountBreakup) {
    if (props.paymentNumber != 0) {
      return (
        <div className="amount-splitup">
          <Table
            style={{
              width: "100%",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow className="fees-type">
                <TableCell scope="row">
                  {/* <Checkbox
                    checked={totalAmtChcked}
                    onChange={(e) => {
                      checkTotal()
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="amt-check-box"
                  /> */}
                  <span className="fees-title">Total Amount</span>
                </TableCell>
                <TableCell align="left"><b>{`$ ${amountBreakup.totalAmountRefund}`}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    } else {
      return (
        <div
          className="amount-splitup"
          style={{
            padding: "10px 0",
          }}
        >
          <Table
            style={{
              width: "350px",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableBody>
              <TableRow className="fees-type">
                <TableCell scope="row">
                  {/* <Checkbox
                    checked={totalAmtChcked}
                    onChange={(e) => {
                      checkTotalAmnt(!totalAmtChcked);
                      checkShareContribution(!totalAmtChcked);
                      checkMembershipDues(!totalAmtChcked);
                      if (amountBreakup.applicationFee != 0.00) checkApplicationFee(!totalAmtChcked);
                    }}
                    color="primary"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                    className="amt-check-box"
                  /> */}
                  <span className="fees-title">Total Amount</span>
                </TableCell>
                <TableCell align="left"><b>{`$ ${amountBreakup.totalAmountRefund}`}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      );
    }
  } else {
    return null;
  }
};

export default RefundAmountSplitup;
