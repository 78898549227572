import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, withStyles } from "@material-ui/core";
import Axios from 'axios';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
}));

export default function EmailCalendarMenu(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        console.log(props.emailJob)
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>

            <div>
                <IconButton
                    style={{
                        outline: 'none'
                    }}
                    ref={anchorRef}

                    onClick={handleToggle}
                >
                    <MoreVertIcon />
                </IconButton>
                <Popper style={{
                    zIndex: '10'
                }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                background: '#eceac7'
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>

                                        {
                                            props.emailJob.status == 'SCHEDULED' || props.emailJob.status == 'FAILED' ?

                                                <MenuItem onClick={() => {
                                                    props.retriggerEmail(props.emailJob, props.emailJobIndx)
                                                }}>Re Trigger</MenuItem>
                                                : null
                                        }

                                        {
                                            props.emailJob.status == 'SCHEDULED' || props.emailJob.status == 'FAILED' ?


                                                <MenuItem onClick={() => {
                                                    props.confirmRemove(props.emailJob)
                                                }}>Remove</MenuItem> : null
                                        }

                                        <MenuItem onClick={() => {
                                            props.viewJobStatus(props.emailJob)
                                        }}>Stats</MenuItem>
                                        <MenuItem onClick={() => {
                                            props.downloadMemberList(props.emailJob)
                                        }}>Download CSV</MenuItem>
                                        <MenuItem onClick={() => {
                                            props.viewTemplate(props.emailJob.templateId)
                                        }}>View</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        </div>
    );
}