
const style = {
dropDown : {
    width: '100%',
    marginTop: '0',
    marginBottom: '0',
    marginLeft: '0px',
    borderRadius: '4px',
    // height: '56px',
    WebkitHeight:'90px'
}
}
export default style;