import React, { Component } from 'react';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
// import Amplify from '@aws-amplify/core';
// import Auth from '@aws-amplify/auth';
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import '../../Assets/CSS/window.css';
import Header from '../Commonscreens/Header'
import MainCard from './Maincard'
import DigitalCard from '../../Assets/Images/Digital Health Card.png';
import Provider from '../../Assets/Images/Find a Provider.png';
import Document from '../../Assets/Images/Documents@3x.png';
import AgentAddActive from '../../Assets/Images/add_agent_active.svg';
import MultipleAgentActive from '../../Assets/Images/add_multiple_agents_active.svg';
import ReportActive from '../../Assets/Images/reports_active_new.svg';
import EncodeURLActive from '../../Assets/Images/encode_urls_active.svg';
import AgentAddInactive from '../../Assets/Images/add_agent.svg';
import MultipleAgentInactive from '../../Assets/Images/add_multiple_agents.svg';
import ReportInactive from '../../Assets/Images/reports_new.svg';
import SearchInactive from '../../Assets/Images/search_user.svg';
import SearchActive from '../../Assets/Images/search_user_active.svg';
import EncodeURLInactive from '../../Assets/Images/encode_urls.svg';
import PaidThruReport from '../../Assets/Images/reports.svg';
import PaidThruReportActive from '../../Assets/Images/reports_active.svg';
import SendMailIcon from '../../Assets/Images/mail.svg';
import SendMailActiveIcon from '../../Assets/Images/mail_active.svg';
import CognitoDashboardIcon from '../../Assets/Images/cognito_dashboard.svg';
import CognitoDashboardActiveIcon from '../../Assets/Images/cognito_dashboard_active.svg';
import CognitoSettingIcon from '../../Assets/Images/cognito_settings.svg';
import CognitoSettingActiveIcon from '../../Assets/Images/cognito_settings_active.svg';
import Loader from '../authentication/loader';
import { Modules } from '../../App';
import { clearState } from '../../store/slice/notificationSlice';
import { loginWithPromise } from '../../store/slice/authSlice';




// const HomeContainer
class Reports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeModule: Modules.Reports,
            menuItems: [
                {
                    name: 'Transaction Report',
                    module: Modules.TransactionReport,
                    cardImage: ReportInactive,
                    cardHoverImage: ReportActive,
                    showCard: true
                },
                {
                    name: 'Delinquency Report',
                    module: Modules.DeliquencyReport,
                    cardImage: ReportInactive,
                    cardHoverImage: ReportActive,
                    showCard: true
                },
                {
                    name: 'Age Audit Report',
                    module: Modules.AgeAudit,
                    cardImage: ReportInactive,
                    cardHoverImage: ReportActive,
                    showCard: true
                },
                {
                    name: 'Recurring Transaction Audit',
                    module: Modules.RecurringTransaction,
                    cardImage: ReportInactive,
                    cardHoverImage: ReportActive,
                    showCard: true
                },
                
                
                // {
                //     name: 'Paid Thru Defaulters Report',
                //     module: Modules.PaidThruReport,
                //     cardImage: PaidThruReport,
                //     cardHoverImage: PaidThruReportActive,
                //     showCard: true
                // },
                // {
                //     name: 'Members Dashboard',
                //     module: Modules.MemberDashboard,
                //     cardImage: PaidThruReport,
                //     cardHoverImage: PaidThruReportActive
                // },
                // {
                //     name: 'Send Mass Emails',
                //     module: Modules.Notification,
                //     cardImage: SendMailIcon,
                //     cardHoverImage: SendMailActiveIcon,
                //     showCard: sessionStorage.getItem('showEmailAdmin') == 'true'
                // },
                // {
                //     name: 'Cognito Dasboard',
                //     module: Modules.CognitoDashboard,
                //     cardImage: CognitoDashboardIcon,
                //     cardHoverImage: CognitoDashboardActiveIcon,
                //     showCard: true
                // },
                
                // {
                //     name: 'Operations Dashboard',
                //     module: Modules.OperationsDashboard,
                //     cardImage: CognitoDashboardIcon,
                //     cardHoverImage: CognitoDashboardActiveIcon,
                //     showCard: true
                // },
            ],
            showLoader: false

        }

        // this.idleTimer = null

    }

    componentDidMount() {
        this.props.clearState();
        // Auth.currentAuthenticatedUser()
        //     .then((user) => {
        //         localStorage.setItem('email', user.attributes.email);
        //         if (sessionStorage.getItem('showEmailAdmin') == null) {
        //             this.getUserRoles(user.attributes.email);
        //         }
        //     }).catch((err) => {
        //         console.log(err)
        //         sessionStorage.setItem('isLogged', false);
        //         this.appLogout();
        //     });
    }

    // getUserRoles = (email) => {
    //     let request = { "username": email };
    //     this.setState({
    //         showLoader: true
    //     })
    //     loginWithPromise().then((token) => {
    //         axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/userrole', request, {
    //             headers: {
    //                 Authorization: token
    //             }
    //         }).then((resp) => {
    //             console.log(resp.data)
    //             let roles = resp.data;
    //             let menuItems = [...this.state.menuItems];
    //             sessionStorage.setItem('showEmailAdmin', 'false')
    //             roles.forEach((item) => {
    //                 if (item.role == "emailadmin") {

    //                     menuItems.forEach((item) => {
    //                         if (item.module == Modules.Notification) {
    //                             item.showCard = true;
    //                             sessionStorage.setItem('showEmailAdmin', 'true')
    //                         }
    //                     });

    //                 }
    //             })
    //             this.setState({
    //                 menuItems: menuItems,
    //                 showLoader: false
    //             })


    //         }).catch((err) => {
    //             console.log(err, 'failed to load templates');
    //             this.setState({
    //                 showLoader: false
    //             })
    //             sessionStorage.setItem('showEmailAdmin', 'false')
    //         });
    //     })

    // }

  
    loadComponent = (moduleKey) => {
        console.log('============== loadComponent =====================');
        console.log(moduleKey);
        // this.setState({
        //     activeModule: moduleKey
        // })

        this.props.history.push(moduleKey)
    }

    handleBack = () => {
        this.setState({
            activeModule: Modules.Reports
        });
    }



    appLogout = () => {
        Auth.signOut();
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/login';
    }



    render() {
       


        return (
            <div className="bottommaincontainer_left">
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '25%', flexWrap: 'wrap', minHeight: '40vh' }}>

                    {
                        this.state.menuItems.map((menu, index) => {
                            if (menu.showCard) {
                                return (
                                    <MainCard key={index}
                                        name={menu.name}
                                        loadComponent={this.loadComponent}
                                        cardImage={menu.cardImage}
                                        moduleKey={menu.module}
                                        cardHoverImage={menu.cardHoverImage} />
                                )
                            } else {
                                return null
                            }

                        })
                    }

                </div>
                <Loader showLoader={this.state.showLoader} />
            </div>
        )
    }

}
const mapStateToProps = (state) => {
    return {

    }
};
// export default connect({}, { clearState })(withRouter(Home));
export default withRouter(connect(mapStateToProps, { clearState })(Reports));