import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import RefundAmountSplitup from './RefundAmountSplitup'
import Radio from '@material-ui/core/Radio';
import { Modal } from 'react-bootstrap';
import './refund.css'
import CommonDropDwn from "./CommonDropDwn_1";
import customStyle from "../CSS/stylesheet_UHS";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox } from "@material-ui/core";
import { isTerminatedMember } from '../../Services/CommonServices';
import ConfirmTermination from './ConfirmTermination';
import PaymentConfirmation from './ConfirmBox';

export const patterns = {
    amount: /^0*[1-9]\d*(\.\d{1,2})?$/,
}

const useStyles = makeStyles({
    saveBtn: {
        fontSize: '13px',
        textTransform: 'none',
    },
    btnContiner: {
        marginTop: '20px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    table: {
        minHeight: '170px'
    },
    label: {
        margin: 0,
        marginTop: '15px',
        paddingLeft: '16px',
        marginBottom: '20px',
        fontSize: '15px'
    }
})

export default function ProdLvlRefundDialogue(props) {
    const classes = useStyles()
    const [amountBreakup, setAmountSplit] = useState([]);
    const [reason, setReason] = useState('');
    const [showAmountBreakup, toggleshowAmountBreakup] = useState(false);

    const [feeType, setFeeType] = useState('')
    const [newAmt, setNewAmt] = useState({});
    const [confirmBtnState, disableConfrm] = useState(true);
    const [refundAdjstmentType, changeRefndAdjstType] = useState('ChargeBack');
    const [refndAdjstTransID, setRefndAdjstTransID] = useState('')
    const [transactionIdError, setTransactionIdError] = React.useState(false);
    const [enable, setEnable] = useState(false)
    const [mainReason, setMainReason] = useState('');
    const [refundReasons, setRefundReasons] = useState(['Can not afford', 'New coverage/other coverage', 'Medicare', 'Medicaid', 'Pre x', 'Deceased', 'Employer coverage', 'Pregnancy', 'Critical illness', 'Drug and alcohol rehab'])
    const [refundData, setRefundData] = useState({})
    const [checkedItems, setCheckedItems] = useState({});
    const [totalAmount, setTotalAmount] = useState(0)
    const [isTotalChecked, setIsTotalChecked] = useState(false)
    const [isTotalDisabled, setIsTotalDisabled] = useState(false)
    const allUnchecked = Object.values(checkedItems).every((isChecked) => !isChecked);
    const anyUnchecked = Object.values(checkedItems).some((isChecked) => !isChecked);
    const allCharged = props.trasctionDataForRefund.filter((item) => item.status === "CHARGED")

    useEffect(() => {
        toggleshowAmountBreakup(true);
        isTerminatedMember(localStorage.getItem('memberIdSource'))
        setAmountSplit(props.trasctionDataForRefund);
        if (props.selectedPaymentMode == 'RefundAdjust') {
            setReason('');
            setMainReason('')
        }
        setTotalAmount(getInitialTotalAmount().toFixed(2))
        if (props.paymentNumber == 1) {
            handleTotalCheck(true)
            setIsTotalDisabled(true)
        }
    }, [totalAmount]);

    useEffect(() => {
        if (allUnchecked) {
            setMainReason('');
            setReason('')
        }
        if (checkedItems && (anyUnchecked || allUnchecked)) setIsTotalChecked(false)
        else {
            if (Object.keys(checkedItems).length == allCharged.length) setIsTotalChecked(true)
            else setIsTotalChecked(false)
        }
    }, [allUnchecked, anyUnchecked]);

    const handleClose = () => {
        props.closerefndpayment()
    };

    const changeReason = (e) => {
        let value = e.target.value;
        setReason(value);
    }
    const selectReason = (e) => {
        setMainReason(e);
        checkForConfmBtn(feeType, e, refndAdjstTransID)
    }

    const confirmRefund = () => {
        let _reason = reason;
        if (props.selectedPaymentMode == 'RefundAdjust') {
            let adjustLabel = 'Chargeback Adjustment'
            if (refundAdjstmentType == 'Adjustment') {
                adjustLabel = 'Refund Adjustment'
            }
            _reason = adjustLabel + ': ' + _reason
        }
        let selectedItems = amountBreakup.filter((item) => checkedItems[item.id]);

        let data = {
            reason: mainReason,
            comment: _reason,
            initiatedBy: "Admin: " + localStorage.getItem('email'),
            source: localStorage.getItem('memberIdSource'),
            components: selectedItems,
        }

        console.log("feeType", data)
        props.setIsTotalChecked(isTotalChecked)
        props.confirmRefund(data)
        props.totalAmount(getTotalAmount())
    }

    const getInitialTotalAmount = () => {
        const chargedItems = amountBreakup.filter((item) => item.status === "CHARGED");
        const totalChargedAmount = chargedItems.reduce((sum, item) => sum + item.amount, 0);
        return totalChargedAmount;
    };

    const getTotalAmount = () => {
        const totalAmount = amountBreakup.reduce((sum, item) => {
            if (checkedItems[item.id]) {
                return sum + item.amount;
            }
            return sum;
        }, 0);
        return totalAmount;
    };

    const checkForConfmBtn = (_feeType, _reason, _refndAdjstTransID) => {
        const areAllFalse = amountBreakup.every(item => item.checked === false);
        if (!areAllFalse) {
            setEnable(true)
            if (_reason && _reason.length > 0) {
                disableConfrm(false)
            } else {
                disableConfrm(true)
            }
            if (props.selectedPaymentMode == 'RefundAdjust') {
                if (_refndAdjstTransID.length > 0) {
                    disableConfrm(false)
                } else {
                    disableConfrm(true)
                }
            }
        }
        else {
            setEnable(false)
            disableConfrm(true)
            setMainReason('')
            setIsTotalChecked(false)
        }
    }

    const handleCheckboxChange = (id, isChecked) => {
        setCheckedItems((prevState) => ({
            ...prevState,
            [id]: isChecked,
        }));
        checkForConfmBtn()
    };

    const handleTotalCheck = (check) => {
        setIsTotalChecked(!isTotalChecked)
        const chargedIds = amountBreakup
            .filter((item) => item.status === "CHARGED")
            .map((item) => item.id);
            console.log('chargedIds: ',check);

        setCheckedItems((prevState) => {
            const updatedCheckedItems = { ...prevState };
            chargedIds.forEach((id) => {
                updatedCheckedItems[id] = check == true ? true : allUnchecked;
            });
            return updatedCheckedItems;
        });
        checkForConfmBtn()
    }

    const getAmountSplitupView = () => {
        if (showAmountBreakup) {
            return (
                <div className={props.selectedPaymentMode == 'RefundAdjust' ? "refund-adjst" : ""}>
                    <div className="amount-splitup">
                        <Table
                            style={{
                                width: "100%",
                            }}
                            size="small"
                            aria-label="a dense table"
                        >
                            <TableBody>
                                {amountBreakup.map(amt => (
                                    <TableRow className="fees-type" sx={{ height: 20 }}>
                                        <TableCell className="prodAmtTableCell" scope="row">
                                            <CheckboxListItem
                                                key={amt.id}
                                                item={amt}
                                                isChecked={checkedItems[amt.id] || false}
                                                onCheckboxChange={handleCheckboxChange}
                                            />
                                        </TableCell>
                                        <TableCell className="prodAmtTableCell" align="left">{`$ ${amt.amount}`}</TableCell>
                                    </TableRow>
                                ))}
                                <TableRow className="fees-type">
                                    <TableCell className="prodAmtTableCell" scope="row">
                                        <Checkbox
                                            id={10}
                                            checked={isTotalChecked}
                                            onChange={handleTotalCheck}
                                            disabled={isTotalDisabled}
                                            color="primary"
                                            inputProps={{ 'aria-label': "Total Amount" }}
                                        />
                                        Total Amount
                                    </TableCell>
                                    <TableCell className="prodAmtTableCell" align="left">{`$ ${totalAmount}`}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            )
        }
    }

    const CheckboxListItem = ({ item, isChecked, onCheckboxChange }) => {
        const handleCheckboxChange = (e) => {
            const { id, checked } = e.target;
            onCheckboxChange(id, checked);
        };

        return (
            <div>
                <Checkbox
                    id={item.id}
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    color="primary"
                    inputProps={{ 'aria-label': item.planName }}
                    disabled={item.status == "REFUNDED" || isTotalDisabled}
                />
                {item.planName}
            </div>
        );
    };

    return (
        <>
        <Modal style={{ marginTop: '3%' }} onClose={handleClose} aria-labelledby="customized-dialog-title" show={props.isOpen} maxWidth={"sm"} fullWidth={true}>
            <Modal.Header style={{ alignItems: 'center' }}>
                <Typography variant="h5" style={{ fontSize: '16px' }}>Refund Payment</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Modal.Header>
            <Modal.Body dividers>
                <div className="refund-dialouge">
                    <p className="month-label">Payment number: <span className="month-no">{props.paymentNumber} </span></p>
                    {/* <p className="msg">Please ensure this is the latest transaction. You should not refund for intervening ones.</p> */}
                </div>
                {getAmountSplitupView()}
                <div >
                    <div style={{
                        display: props.selectedPaymentMode == 'RefundAdjust' ? 'flex' : 'none'
                    }}>
                        <div>
                            <Radio
                                checked={refundAdjstmentType == 'ChargeBack'}
                                onChange={() => {
                                    changeRefndAdjstType('ChargeBack')
                                    setReason('');
                                    setMainReason('')
                                    disableConfrm(true)
                                    setTransactionIdError(false)
                                    setRefndAdjstTransID('')
                                }}
                                name="Chargeback Adjustment"
                                style={{
                                    color: '#35b8b9'
                                }}
                            />
                            <span style={{ fontSize: '13px' }}>Chargeback</span>
                        </div>
                        <div>
                            <Radio
                                checked={refundAdjstmentType == 'Adjustment'}
                                onChange={() => {
                                    changeRefndAdjstType('Adjustment');
                                    setReason('');
                                    setMainReason('')
                                    disableConfrm(true)
                                    setTransactionIdError(false)
                                    setRefndAdjstTransID('')
                                }}
                                name="Refund Adjustment"
                                style={{
                                    color: '#35b8b9'
                                }}
                            />
                            <span style={{ fontSize: '13px' }}>Refund</span>
                        </div>
                    </div>

                    <div className={classes.label} style={{
                        display: props.selectedPaymentMode == 'RefundAdjust' ? 'flex' : 'none',
                        alignItems: 'center'
                    }}>

                        <div style={{
                            width: '40%',
                            display: ' inline-block',
                            marginTop: '10px'
                            // marginTop: props.selectedPaymentMode == 'RefundAdjust' ? '10px' : '0'
                        }}>
                            Chargeback/Refund Transaction ID: </div>
                        <TextField
                            inputProps={{
                                style: {
                                    fontSize: '14px',
                                    paddingLeft: '10px'
                                }
                            }}
                            placeholder="Enter Transaction ID"
                            value={refndAdjstTransID}
                            style={{
                                width: '50%'
                            }}
                            error={transactionIdError}
                            helperText={transactionIdError ? 'Enter a Valid Transaction ID' : ''}
                            onChange={(e) => {
                                let value = e.target.value.trim()
                                let isPatternMatched = value.match(patterns.amount);
                                setTransactionIdError(!isPatternMatched)
                                setRefndAdjstTransID(value);
                                checkForConfmBtn(feeType, reason, value)
                                // checkForRefndAdjstConfm(amountBreakup.totalAmountRefund, mainReason, value, !isPatternMatched)
                            }}
                        // helperText={'Less than 300 $'}
                        />
                    </div>
                    <div className='refundReasonDiv'>
                        <div style={{
                            width: '45%',
                            display: ' inline-block',
                        }}>
                            <CommonDropDwn setChild={selectReason} name={'RefundReason'} label={'Select Reason'} value={mainReason} disable={allUnchecked} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select refund reason'} List={refundReasons} parentDetails={{ label: 'Refund_Reason' }}></CommonDropDwn>
                        </div>
                        <TextField
                            variant="outlined"
                            label={'Enter Additional Data'}
                            inputProps={{
                                style: {
                                    fontSize: '14px'
                                },
                                maxLength: 200
                            }}
                            placeholder="Enter Additional Data"
                            value={reason}
                            style={{
                                width: '45%',
                                marginLeft: '40px'
                            }}
                            onChange={changeReason}
                            disabled={mainReason == ""}
                            helperText={"Supports up to 200 characters."}
                            multiline
                            rows={3}
                            maxRows={3}
                        />
                    </div>
                </div>
                <div className={classes.btnContiner}> <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.saveBtn}
                    onClick={confirmRefund}
                    disabled={mainReason == ""}
                >Confirm</Button></div>
            </Modal.Body>
        </Modal>
            {props.isTotalChecked && !JSON.parse(localStorage.getItem("isTerminatedMember")) ?
                <ConfirmTermination open={props.showConfirm} handleClose={props.closeConfirm} paymentRequest={props.paymentRequest} memberDetails={props.memberDetails} accept={props.accept} isRefund={true} selectedPaymentMode={props.selectedPaymentMode} />
                :
                <PaymentConfirmation open={props.showConfirm} handleClose={props.closeConfirm} paymentRequest={props.paymentRequest} memberDetails={props.memberDetails} accept={props.accept} isRefund={true} selectedPaymentMode={props.selectedPaymentMode} />
            }
        </>
    );
}
