import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { SendGridRequestURL, sendGridAxiosConfig } from './sendGridConfig';
import AlertDialog from '../../Commonscreens/AlertDialog';
import ConfirmBox from '../../Commonscreens/ConfirmBox';
import styles from './notification.module.scss';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toggleLoader } from '../../../store/slice/notificationSlice';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TemplateViewModal from './TemplateViewModal';

const TEMPLATE_TYPE = {
    ADD: '021',
    EDIT: '001',
    DELETE: '024'
}
export default function TemplateDynamicValues() {
    let params = useParams();
    let history = useHistory();
    const dispatch = useDispatch();
    const [fields, setFields] = useState([]);
    const [fieldName, setFieldName] = useState('');
    const [showAlert, toggleAlert] = useState(false);
    const [messageText, setMessageText] = useState('');
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(-1);
    const [showTemplate, toggleTemplateView] = useState(false);
    const [mode, setMode] = useState('ADD');
    const [templateIdfromURL, setTemplateIDfromURL] = useState('')

    const [confirmationBoxDetails, setConfirmBoxDetails] = useState({
        message: '',
        open: false,
        handleClose: () => { },
        accept: () => { }
    });

    const selectedEmailTemplateContent = useRef('')

    let emailTemplate = useSelector((state) => state.notification.selectedEmailTemplate);
    if (!emailTemplate.templateName) {
        emailTemplate = JSON.parse(sessionStorage.getItem('selectedEmailTemplate')) || {
            templateName: '',
            createdBy: ''
        }
    }

    useEffect(() => {
        let templateId = params.templateId
        if (templateId != 'new') {
            setMode('EDIT')
            setTemplateIDfromURL(templateId);
        }
        // debugger
        // getTemplateDynamicValues(templateId)
        getTemplatesFromSendGrid(templateId)
    }, [])


    const getTemplatesFromSendGrid = async (templateId) => {
        dispatch(toggleLoader(true));

        await Axios.get(SendGridRequestURL + 'templates?generations=dynamic', sendGridAxiosConfig).then((resp) => {
            //templateIdfromURL
            let templateList = resp.data.templates

            setEmailTemplates(templateList)
            let selectedTemplateId = null;
            if (templateId) {
                templateList.forEach((item, index) => {
                    if (item.id == templateId) {
                        selectedTemplateId = templateId;
                        setSelectedTemplateIndex(index);
                        // getEmailTemplateContent(templateId);

                    }
                })
            }
            if (selectedTemplateId) {
                getTemplateDynamicValues(selectedTemplateId)
            } else {
                dispatch(toggleLoader(false));
            }


        }).catch((err) => {
            console.log(err, 'failed to load templates')
            dispatch(toggleLoader(false));
        })

    };



    const getTemplateDynamicValues = (templateId) => {
        dispatch(toggleLoader(true));
        Axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/getemailtemplatefields?templateId=' + templateId, {
            headers: {
                Authorization: sessionStorage.getItem('csrtoken')
            }
        }).then((resp) => {
            setFields(resp.data)
            dispatch(toggleLoader(false));


        }).catch((err) => {
            console.log(err, 'failed to load templates');
            dispatch(toggleLoader(false));
        })

    }

    const addField = () => {
        // let templateId = params.templateId
        // dispatch(toggleLoader(true));
        let dynamicFields = [];
        fields.forEach((item) => {
            dynamicFields.push(item)
        });
        dynamicFields.push({
            fieldName: fieldName,
            id: new Date().getTime() + fields.length
        })
        setFields(dynamicFields);
        setFieldName('')

    }

    const confirmRemove = (item) => {
        setConfirmBoxDetails({
            message: 'Are u sure want to remove the item?',
            open: true,
            handleClose: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false })
            },
            accept: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false })
                // removeEmailJob(item)
            }
        })
    }
    const removeField = (item, index) => {
        let updatedFields = [...fields];
        updatedFields.splice(index, 1)
        setFields(updatedFields);
    }
    const saveTemplate = () => {
        console.log('::selectedTemplateIndex::', selectedTemplateIndex)


        let template = emailTemplates[selectedTemplateIndex];
        let dynamicFields = fields.map((item) => item.fieldName);
        let request = {};

        if (mode == 'EDIT') {
            request = {
                "templateId": template.id,
                "templateName": template.name,
                "createdBy": localStorage.getItem('email') || 'Admin',
                "updatedBy": localStorage.getItem('email') || 'Admin',
                "status": "ACTIVE",
                "maintainenceType": TEMPLATE_TYPE.EDIT,
                "dynamicFields": dynamicFields

            }
        } else {
            request = {
                "templateId": template.id,
                "templateName": template.name,
                "createdBy": localStorage.getItem('email') || 'Admin',
                "updatedBy": localStorage.getItem('email') || 'Admin',
                "status": "ACTIVE",
                "maintainenceType": TEMPLATE_TYPE.ADD,
                "dynamicFields": dynamicFields

            }
        }

        console.log('::request::', request)
        dispatch(toggleLoader(true));
        Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/emailtemplate', request, {
            headers: {
                Authorization: sessionStorage.getItem('csrtoken')
            }
        }).then((resp) => {
            console.log(resp.data)
            dispatch(toggleLoader(false));
            toggleAlert(true);
            if (mode == 'ADD') {
                setSelectedTemplateIndex(-1);
                setFields([])
            }
            setFieldName('')
            setMessageText('Template Saved Successfully!')
            history.push('/notification/email-template-list')

        }).catch((err) => {
            console.log(err, 'failed to update templates');
            toggleAlert(true);
            let msg = err.response.data || 'Some error occured. Please try again.'
            setMessageText(msg)
            dispatch(toggleLoader(false));
        })
    }

    const getInsideDoubleCurly = (str) => str.split('{{')
        .filter(val => val.includes('}}'))
        .map(val => val.substring(0, val.indexOf('}}')));

    const getEmailTemplateContent = (templateId) => {
        dispatch(toggleLoader(true));
        Axios.get(SendGridRequestURL + 'templates/' + templateId, sendGridAxiosConfig).then((resp) => {

            let plain_content = resp.data.versions.length > 0 ? resp.data.versions[0].plain_content : '';
            selectedEmailTemplateContent.current = plain_content;

            let fieldsFromTemplate = [...new Set(getInsideDoubleCurly(plain_content))]
            console.log(fieldsFromTemplate)
            let dynamicFields = [];
            fieldsFromTemplate.forEach((item, index) => {
                dynamicFields.push({
                    fieldName: item,
                    id: new Date().getTime() + index
                })
            });

            setFields(dynamicFields);

            dispatch(toggleLoader(false));

        }).catch((err) => {
            console.log(err, 'failed to load templates')
            dispatch(toggleLoader(false));

        })
    }

    console.log('::fields::', fields)
    return (
        <div className={styles.emailContainer}>
            <div className={styles.dynamicValues} >
                {/* <div className={styles.title}>{emailTemplate.templateName}</div> */}
                <div className={styles.jobDetails}>
                    <div className={styles.flexContainer} style={{
                        marginTop: '10px'
                    }}>
                        <p className={styles.label}>Choose Template: </p>
                        <Select
                            style={{ width: '40%', textAlign: 'left', color: 'black' }}
                            // native
                            disabled={mode == 'EDIT'}
                            value={selectedTemplateIndex}
                            onChange={(event) => {
                                let value = event.target.value;
                                setSelectedTemplateIndex(value);
                                // getEmailTemplateContent(emailTemplates[value].id);

                            }}
                        >

                            {
                                emailTemplates.map((template, index) => {
                                    return <MenuItem key={template.id} value={index}>{template.name}</MenuItem>
                                })
                            }
                        </Select>
                        <IconButton aria-label="view template"
                            disabled={selectedTemplateIndex < 0}
                            color="primary"
                            style={{
                                textTransform: 'none',
                                // marginTop: '20px',
                                borderRadius: '4px',
                                background: '#e8e8e8',
                                marginLeft: '1px',
                                height: '40px',
                                width: '40px'
                            }}
                            onClick={() => {
                                toggleTemplateView(true)
                            }}>

                            <VisibilityIcon />
                        </IconButton>

                    </div>
                    {/* <div className={styles.flexContainer}>
                        <p className={styles.label}>Template Name: </p>
                        <TextField
                            // value={fieldName}
                            style={{ width: '40%' }}
                            onChange={(e) => {
                                // setFieldName(e.target.value)
                            }}
                            label={'Template Name'} />

                    </div> */}

                    <div className={styles.fieldsMain}>
                        <p className={styles.fieldMainTitle}>Add Fields to Template</p>
                        <div className={styles.flexContainer}>

                            <TextField
                                value={fieldName}
                                style={{ width: '40%' }}
                                onChange={(e) => {
                                    setFieldName(e.target.value)
                                }}
                                disabled={selectedTemplateIndex < 0}
                                label={'Enter Field Name Same as given in the Template.'} />

                            <Button variant="contained"
                                size="small"
                                color="primary"
                                disabled={selectedTemplateIndex < 0 || (
                                    fieldName.trim().length <= 0
                                )}
                                style={{
                                    textTransform: 'none',
                                    marginLeft: '20px'
                                }}
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={addField}
                            >Add</Button>
                        </div>

                        <div className={styles.fieldContainer}>
                            {
                                fields.map((item, index) => {
                                    return (
                                        <div key={item.id} className={styles.fieldItem}>
                                            <span>{item.fieldName}</span>
                                            <span className={styles.delete} onClick={() => {
                                                removeField(item, index)
                                            }}>
                                                <HighlightOffIcon />
                                            </span>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>


                </div>

            </div>
            <div>
                <Button variant="contained"
                    // disabled={selectedTemplateIndex < 0 || fields.length < 1}
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: '10px',
                        color: 'white',
                        padding: '3px 10px'
                    }}
                    onClick={() => {
                        history.goBack();
                    }}
                >Back</Button>
                <Button variant="contained"
                    disabled={selectedTemplateIndex < 0 || fields.length < 1}
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: '10px',
                        color: 'white',
                        padding: '3px 10px'
                    }}
                    onClick={saveTemplate}
                >Save Template</Button>
            </div>

            <AlertDialog open={showAlert} messageText={messageText} closeAlert={() => toggleAlert(false)} />
            <ConfirmBox
                open={confirmationBoxDetails.open}
                message={confirmationBoxDetails.message}
                accept={confirmationBoxDetails.accept}
                handleClose={confirmationBoxDetails.handleClose}
            />

            {
                showTemplate &&
                <TemplateViewModal templateId={emailTemplates[selectedTemplateIndex].id} toggleTemplateView={toggleTemplateView}
                    toggleAlert={toggleAlert}
                    setMessageText={setMessageText}
                />
            }
        </div>
    );
}