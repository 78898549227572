
export const getDateInUTC = (date, getInMillisecs) => {
  if (date) {
    let newDateTime = date + new Date(date).getTimezoneOffset() * 60 * 1000;
    if (getInMillisecs) {
      return newDateTime
    }
    return new Date(newDateTime)
  }
  return date
}

export const compareValues = (key, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) return 0;
    let comparison = -1;
    if (a[key] && b[key]) {
      comparison = a[key].toString().localeCompare(b[key].toString());
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export const validateEmail = (mail) => {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(mail);

}

export const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}