import React, { Component, useEffect } from "react";
import { Auth } from "aws-amplify";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IdleTimer from 'react-idle-timer'

export const IdleSessionHandle = () => {

  const timeoutInMinutes = process.env.REACT_APP_session_time_minutes;
  const timeInMilliseconds = 1000 * 60 * timeoutInMinutes;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    let checkForSessionIntervalTime = 1000 * 60 * 2;
    let checkForSessionInterval = setInterval(() => {
      // console.log('auth api')
      Auth.currentAuthenticatedUser()
        .then((user) => {
          // console.log('user available');
        }).catch((err) => {
          console.log('no session available');
          appLogout()
        });
    }, checkForSessionIntervalTime);

    return () => clearInterval(checkForSessionInterval); //This is important
  }, []);

  const handleClose = () => {
    window.location.href = '/login';
    setOpen(false);
  };


  const handleOnIdle = (event) => {
    // Auth.currentAuthenticatedUser()
    //   .then((user) => {
    //     appLogout()
    //   }).catch((err) => {
    //     console.log('no session available')
    //   });
    appLogout()
    console.log('app is idle now')
  }

  const appLogout = () => {
    setOpen(true);
    Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    // setTimeout(() => {
    //   window.location.href = '/login';
    // }, 5000);
  }
  // console.log('timeInMilliseconds::',timeInMilliseconds)
  return (
    <div>
      <IdleTimer
        ref={ref => {
          // this.idleTimer = ref
        }}
        timeout={timeInMilliseconds}
        onActive={(event) => {
          // console.log('user on active', event)
        }}
        onIdle={handleOnIdle}
        onAction={(event) => {
          // console.log('user on action', event)
        }}
        debounce={250}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <DialogTitle id="alert-dialog-title">{"Session Expired"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Your session has been expired. Please try logging again!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose} color="primary">
              Disagree
          </Button> */}
          <Button onClick={handleClose} color="primary">
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  )


}
export default IdleSessionHandle;
