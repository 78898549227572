import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    emailTemplates: [],
    selectedEmailTemplateId: null,
    showLoader: false,
    memberDataSet: [],
    memberDataSetFileName: null,
    selectedTemplateDynamicValues: [],
    memberDataColumns: [],
    memberDataSetBase64: null,
    selectedEmailTemplate: {
        templateName: '',
        createdBy: ''
    }
}
export const notificationSlice = createSlice({
    name: 'notification',
    initialState: initialState,
    reducers: {
        setEmailTemplates: (state, action) => {
            state.emailTemplates = action.payload
        },
        setEmailTemplateId: (state, action) => {
            state.selectedEmailTemplateId = action.payload
        },
        setSelectedEmailTemplate: (state, action) => {
            sessionStorage.setItem('selectedEmailTemplate', JSON.stringify(action.payload));
            state.selectedEmailTemplate = action.payload;
            state.selectedEmailTemplateId = action.payload.templateId;
        },
        toggleLoader: (state, action) => {
            state.showLoader = action.payload
        },
        setMemberDataSet: (state, action) => {
            state.memberDataSet = action.payload.data;
            state.memberDataSetFileName = action.payload.fileName;
        },
        setSelectedTemplateDynamicValues: (state, action) => {
            state.selectedTemplateDynamicValues = action.payload
        },
        updateTemplateDynamicValues: (state, action) => {
            state.selectedTemplateDynamicValues[action.payload.index][action.payload.key] = action.payload.value
        },
        setMemberDataColumns: (state, action) => {
            state.memberDataColumns = action.payload
        },
        setMemberDataSetBase64: (state, action) => {
            state.memberDataSetBase64 = action.payload
        },
        clearState: (state) => {
            return initialState
        }
    }
});

export const { setEmailTemplates, toggleLoader, setEmailTemplateId, setMemberDataColumns, setSelectedTemplateDynamicValues, setMemberDataSet, setMemberDataSetBase64, updateTemplateDynamicValues, clearState, setSelectedEmailTemplate } = notificationSlice.actions;
export default notificationSlice.reducer;