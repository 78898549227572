import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, Router, useHistory } from 'react-router-dom';
import Axios from 'axios';
import moment from "moment";
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    DateTimePicker
} from '@material-ui/pickers';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Modules } from '../../../App';
import { useSelector, useDispatch } from 'react-redux';
import { SendGridRequestURL, sendGridAxiosConfig } from './sendGridConfig';
import { setEmailTemplates, toggleLoader, setEmailTemplateId, setSelectedTemplateDynamicValues, setMemberDataSet, setMemberDataColumns, setMemberDataSetBase64, updateTemplateDynamicValues, setSelectedEmailTemplate, clearState } from '../../../store/slice/notificationSlice';
import CSVReader from './CSVReader';
import styles from './notification.module.scss';

import { loginWithPromise } from '../../../store/slice/authSlice'
import { getDateInUTC } from '../../../helper/utils';
import AlertDialog from '../../Commonscreens/AlertDialog';
import TestEmail from './TestEmail';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TemplateViewModal from './TemplateViewModal';
import Menu from '@material-ui/core/Menu';

const getInitalDate = () => {
    let date = new Date();
    date.setDate(date.getDate() + 1);
    date.setHours(0, 0, 0, 0)
    return date;
}

const SendEmail = (props) => {
    const dispatch = useDispatch()
    let location = useHistory();
    const emailTemplates = useSelector((state) => state.notification.emailTemplates);
    const templateId = useSelector((state) => state.notification.selectedEmailTemplateId);
    const selectedEmailTemplate = useSelector((state) => state.notification.selectedEmailTemplate);

    const memberDataSet = useSelector((state) => state.notification.memberDataSet);
    const selectedTemplateDynamicValues = useSelector((state) => state.notification.selectedTemplateDynamicValues);
    const memberDataColumns = useSelector((state) => state.notification.memberDataColumns);

    const memberDataSetFileName = useSelector((state) => state.notification.memberDataSetFileName);
    const memberDataSetBase64 = useSelector((state) => state.notification.memberDataSetBase64);
    const [selectedDate, handleDateChange] = useState(getInitalDate());
    const [invalidDateMessage, setInvalidDateMessage] = useState('');
    const [showSettings, toggleSettingsView] = useState(false)
    const [showAlert, toggleAlert] = useState(false);
    const [messageText, setMessageText] = useState('Email job created successfully.');
    const [jobName, setJobName] = useState('');
    const [senderList, setSenderList] = useState([]);
    const [unsubscribeList, setUnsubscribeList] = useState([]);
    const [selectedSenderId, setSelectedSenderId] = useState('Select');
    const [selectedUnsubscribeGroup, setSelectedUnsubscribeGroup] = useState('Select');
    const [showTestEmail, toggleShowTestEmail] = useState(false)
    const [openDaterPicker, toggleDatePicker] = useState(false);
    const [showTemplate, toggleTemplateView] = useState(false);
    const [testEmailRun, toggleTestEmailRun] = useState(false);
    const templatesFromSendGrid = useRef([])


    const [anchorEl, setAnchorEl] = React.useState(null);

    const menuOnClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const menuOnClose = () => {
        setAnchorEl(null);
    };



    let history = useHistory()
    useEffect(() => {
        getSendersFromSendGrid()
        getEmailTemplates()
        // if (emailTemplates && emailTemplates.length <= 0) {
        //     getEmailTemplates()
        // }
        showSettingsBlock(memberDataSet, selectedTemplateDynamicValues)

        return () => {
            dispatch(clearState());
        }
    }, []);


    const getSendersFromSendGrid = async () => {
        dispatch(toggleLoader(true));

        await Axios.get(SendGridRequestURL + 'marketing/senders', sendGridAxiosConfig).then((resp) => {
            if (resp.data && resp.data.length > 0) {
                setSenderList(resp.data)
            }
        }).catch((err) => {
            console.log(err, 'failed to load templates')
        });


        await Axios.get(SendGridRequestURL + 'asm/groups', sendGridAxiosConfig).then((resp) => {
            if (resp.data && resp.data.length > 0) {
                setUnsubscribeList(resp.data)
            }
        }).catch((err) => {
            console.log(err, 'failed to load templates')
        })

        await Axios.get(SendGridRequestURL + 'templates?generations=dynamic', sendGridAxiosConfig).then((resp) => {
            //templateIdfromURL
            let templateList = resp.data.templates
            templatesFromSendGrid.current = templateList;

        }).catch((err) => {
            console.log(err, 'failed to load templates')
        })

        dispatch(toggleLoader(false));


    };


    /**
     * Changes made for userstory BP-1229
     */

    const getEmailTemplates = () => {
        dispatch(toggleLoader(true));

        loginWithPromise().then((token) => {
            Axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/getemailtemplates', {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {

                let templateList = resp.data.filter((item) => item.status == 'ACTIVE');
                dispatch(setEmailTemplates(templateList));
                dispatch(toggleLoader(false));
            }).catch((err) => {
                console.log(err, 'failed to load templates');
                dispatch(toggleLoader(false));
            });
        })

    };


    const getTemplateDynamicValues = (templateId) => {
        dispatch(toggleLoader(true));
        Axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/getemailtemplatefields?templateId=' + templateId, {
            headers: {
                Authorization: sessionStorage.getItem('csrtoken')
            }
        }).then((resp) => {

            dispatch(toggleLoader(false));
            let templateDynamicValues = resp.data;
            templateDynamicValues.forEach((item) => {
                item['mappedColumnName'] = 'Select';
                item['testValue'] = '';
            })
            dispatch(setSelectedTemplateDynamicValues(templateDynamicValues));
            showSettingsBlock(memberDataSet, templateDynamicValues);

        }).catch((err) => {
            console.log(err, 'failed to load templates');
            dispatch(toggleLoader(false));
        })

    }

    const showSettingsBlock = (_memberDataSet, _selectedTemplateDynamicValues) => {
        // _memberDataSet && _memberDataSet.length > 0 && 
        if (_selectedTemplateDynamicValues && _selectedTemplateDynamicValues.length > 0) {
            toggleSettingsView(true)
        }
    }


    const changeMappedColumnName = (value, index) => {
        dispatch(updateTemplateDynamicValues({
            index, value, key: 'mappedColumnName'
        }));
    }

    const changeTestValue = (value, index) => {
        dispatch(updateTemplateDynamicValues({
            index, value, key: 'testValue'
        }));
    }
    const submitEmailJob = () => {

        if (testEmailRun) {

            let dateInUTC = getDateInUTC(selectedDate.getTime());
            let triggerDateAndTime = moment(dateInUTC).format('YYYY-MM-DD HH:mm:ss');

            let notificationFieldMappings = [];
            selectedTemplateDynamicValues.forEach((item) => {
                notificationFieldMappings.push({
                    // "templateId": templateId,
                    "fieldName": item.fieldName,
                    "mappingColumn": item.mappedColumnName == 'Select' ? '' : item.mappedColumnName
                });
            })
            let displayName = 'InnovativePartners';

            if (senderList && senderList.length > 0) {
                displayName = senderList.filter((item) => {
                    return item.from.email == selectedSenderId;
                })[0].from.name;
            }

            let emailSubject = 'No Subject';
            templatesFromSendGrid.current.forEach((template) => {
                if (template.id == templateId) {
                    if (template.versions && template.versions.length > 0) {
                        emailSubject = template.versions[0].subject
                    }
                }
            })


            let request = {
                "createdBy": localStorage.getItem('email'),
                "triggerTime": triggerDateAndTime,
                "dataSet": memberDataSetBase64.split(',')[1],
                "notificationFieldMappings": notificationFieldMappings,
                "noOfFields": memberDataColumns.length - 1,
                "templateId": templateId,
                "fromId": selectedSenderId,
                "unsubscribeGroup": selectedUnsubscribeGroup,
                "jobName": jobName,
                "emailSubject": emailSubject,
                "displayName": displayName
            }
            console.log('::request::', request);
            dispatch(toggleLoader(true));
            Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/schedulenotification', request, {
                headers: {
                    Authorization: sessionStorage.getItem('csrtoken')
                }
            }).then((resp) => {
                console.log(resp.data)
                setMessageText('Email job created successfully.')
                toggleAlert(true);
                dispatch(toggleLoader(false));
            }).catch((err) => {

                setMessageText("Something went wrong. Unable to Schedule notification")
                toggleAlert(true);
                console.log(err, 'failed to submit job');
                dispatch(toggleLoader(false));
            })
        } else {
            setMessageText('Please run a test email before submitting the job.')
            toggleAlert(true);
        }

    }

    const disableSubmitBtn = () => {
        return memberDataSet.length <= 0 || !templateId || jobName.length <= 0
            || selectedSenderId == 'Select' || !testEmailRun
        // || selectedUnsubscribeGroup == 'Select'
    }

    const disableTestEmailBtn = () => {
        return !templateId
            || selectedSenderId == 'Select'
        // || selectedUnsubscribeGroup == 'Select'
    }

    const sendTestEmail = (emailList) => {
        // console.log(emailList)
        toggleShowTestEmail(false)



        let emailData = {}
        selectedTemplateDynamicValues.forEach((item) => {
            emailData[item.fieldName] = item.testValue || '';
        })
        dispatch(toggleLoader(true));
        let testEmailPromisesList = [];

        let displayName = 'InnovativePartners';

        if (senderList && senderList.length > 0) {
            displayName = senderList.filter((item) => {
                return item.from.email == selectedSenderId;
            })[0].from.name;
        }
        emailList.forEach((email) => {
            let testEmailPromise = new Promise((resolve, reject) => {
                let request = {
                    "email": {
                        "templateId": templateId,
                        "to": email,
                        "cc": "",
                        "bcc": "",
                        "fromid": selectedSenderId,
                        "groupId": selectedUnsubscribeGroup,
                        "displayName": displayName,
                        "data": emailData
                    }
                }

                console.log('::request::', request);

                Axios.post(process.env.REACT_APP_BaseAPIURL + 'v2/email', request, {
                    headers: {
                        // Authorization: sessionStorage.getItem('csrtoken')
                    }
                }).then((resp) => {
                    // console.log(resp.data)
                    resolve('Success')
                    // setMessageText('Email job created successfully.')
                    // toggleAlert(true);
                    // dispatch(toggleLoader(false));
                }).catch((err) => {
                    reject('Failed')
                    // setMessageText("Something went wrong. Unable to Schedule notification")
                    // toggleAlert(true);
                    console.log(err, 'failed to submit job');
                    // dispatch(toggleLoader(false));
                })
            });
            testEmailPromisesList.push(testEmailPromise)
        });

        Promise.allSettled(testEmailPromisesList).then((values) => {
            console.log(values);
            dispatch(toggleLoader(false));
            toggleTestEmailRun(true)
            setMessageText("Test Email has been triggered from System.")
            toggleAlert(true);
        }).catch((err) => {
            dispatch(toggleLoader(false));
            setMessageText("Some error occured. Please try again.")
            toggleAlert(true);
        })


    }

    const getEmailSubject = () => {
        let emailSubject = 'No Subject';
        templatesFromSendGrid.current.forEach((template) => {
            if (template.id == templateId) {
                if (template.versions && template.versions.length > 0) {
                    emailSubject = template.versions[0].subject
                }
            }
        })
        return emailSubject;
    }


    return (

        <div className={styles.emailContainer}>

            <div className={styles.item} style={{
                width: '100%'
            }}>
                {/* <TextField variant="filled" style={{ width: '80%' }}
                    label={'To:'}
                /> */}

                <CSVReader
                    setMemberDataSet={(dataSet, name) => {
                        dispatch(setMemberDataSet({
                            data: dataSet,
                            fileName: name
                        }))
                        showSettingsBlock(dataSet, selectedTemplateDynamicValues);
                    }}
                    setMemberDataColumns={(data) => {
                        dispatch(setMemberDataColumns(data))
                    }}
                    setMemberDataSetBase64={(data) => {
                        dispatch(setMemberDataSetBase64(data));
                    }}
                    saveFileName={(name) => {
                        console.log(name)
                    }}
                />

                {/* <button
                    type='button'
                    onClick={() => {
                        history.push(Modules.MemberDashboard)
                    }}
                    className={styles.createDatasetBtn}
                >
                    Select Members
                    </button> */}

                <Button variant="contained"
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        marginLeft: '15px'
                    }}
                    onClick={() => {
                        history.push(Modules.MemberDashboard)
                    }}
                >  Create Member Data CSV</Button>

                <div style={{
                    marginLeft: 'auto',
                    display: 'none'
                }}>
                    <Button aria-controls="simple-menu" aria-haspopup="true"
                        variant="outlined"
                        color="primary"
                        startIcon={<ExpandMoreIcon />}
                        onClick={menuOnClick}>
                        More
                        </Button>
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={menuOnClose}
                    >
                        <MenuItem onClick={() => {
                            history.push('/notification/email-history')
                        }}>Email History</MenuItem>
                        <MenuItem onClick={() => {
                            history.push('/notification/email-template-list')
                        }}>Template Mangement</MenuItem>
                    </Menu>

                </div>

                {/* <Button
                    variant="outlined"
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginLeft: 'auto'
                    }}
                    onClick={() => {
                        history.push('/notification/email-history')
                    }}
                    startIcon={<ListAltIcon />}
                >Email History</Button>

                <Button
                    variant="outlined"
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginLeft: '10px'
                    }}
                    onClick={() => {
                        history.push('/notification/email-template-list')
                    }}
                    startIcon={<ListAltIcon />}
                >Template Mangement</Button> */}

            </div>

            <div hidden={memberDataSet.length <= 0} className={styles.dataSet}>
                <p>
                    Member Dataset Loaded:
                    <span>{memberDataSetFileName}</span>
                </p>
            </div>

            <div className={styles.item} style={{
                marginBottom: 0
            }}>
                <FormControl variant="filled" style={{ width: '60%' }}>
                    <InputLabel id="demo-simple-select-filled-label">{'Choose Template'}</InputLabel>
                    <Select
                        value={templateId}
                        onChange={(event) => {

                            let tempId = event.target.value
                            // dispatch(setEmailTemplateId(tempId))
                            let template = emailTemplates.filter((item) => item.templateId == tempId)[0]
                            dispatch(setSelectedEmailTemplate(template))
                            getTemplateDynamicValues(tempId)
                            toggleTestEmailRun(false)

                        }}
                        style={{ textAlign: 'left' }}
                        required={true}
                        disabled={props.disableAll}
                    >
                        {
                            emailTemplates.map((template) => {
                                return <MenuItem key={template.templateId} value={template.templateId}>{template.templateName}</MenuItem>
                            })
                        }

                    </Select>
                </FormControl >

                <IconButton aria-label="view template"
                    disabled={!templateId}
                    color="primary"
                    style={{
                        textTransform: 'none',
                        // marginTop: '20px',
                        borderRadius: '4px',
                        background: '#e8e8e8',
                        marginLeft: '1px',
                        height: '56px',
                        width: '56px'
                        // marginLeft: 'auto',

                        // color: 'white',

                        // padding: '3px 10px'
                    }}
                    onClick={() => {
                        if (templateId) {
                            toggleTemplateView(true)
                            // history.push('/notification/email-templates/' + templateId)
                        }
                    }}>

                    <VisibilityIcon />
                </IconButton>
                {/* <Button variant="contained"
                    disabled={!templateId}
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: 'auto',
                        color: 'white',
                        // padding: '3px 10px'
                    }}
                    onClick={() => {
                        if (templateId) {
                            history.push('/notification/email-templates/' + templateId)
                        }
                    }}
                >View Template</Button> */}

                {/* <Button variant="contained"
                    // disabled={!templateId}
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: '10px',
                        color: 'white',
                        // padding: '3px 10px'
                    }}
                    onClick={() => {
                        history.push('/notification/email-template-list')

                        if (templateId) {
                            // history.push('/notification/template-values/' + templateId)

                        }
                    }}
                >Dynamic Values</Button> */}

            </div>
            <div className={styles.subjectStyle} hidden={!showSettings}>
                <p className={styles.subjectTitle}>Subject:</p>
                <p className={styles.content}>{getEmailSubject()}</p>
            </div>

            <div className={styles.settings} hidden={!showSettings}>
                {/* Mapping Table starts */}

                <div className={styles.mappingTable}>
                    <p>Template to Dataset Mapping</p>

                    <div className={styles.table}>
                        <Table size="small" aria-label="a dense table" style={{
                            minHeight: '100px'
                        }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Template Value</TableCell>
                                    <TableCell align="left">Dataset Value</TableCell>
                                    <TableCell align="left">Test Value (for Testing only)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    selectedTemplateDynamicValues.map((template, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row"
                                                    style={{
                                                        width: '170px',
                                                        color: 'black',
                                                        borderColor: 'white'
                                                    }}>

                                                    {template.fieldName}
                                                </TableCell>

                                                <TableCell align="left" style={{
                                                    borderColor: 'white'
                                                }}>
                                                    <Select
                                                        disabled={!memberDataSet || memberDataSet.length <= 0}
                                                        style={{
                                                            width: '90%',
                                                            minWidth: '400px'
                                                        }}
                                                        // native
                                                        value={template.mappedColumnName}
                                                        onChange={(event) => {
                                                            changeMappedColumnName(event.target.value, index)
                                                        }}
                                                    >
                                                        {
                                                            memberDataColumns.map((column) => {
                                                                return <MenuItem key={column} value={column}>{column}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                </TableCell>

                                                <TableCell align="left" style={{
                                                    borderColor: 'white'
                                                }}>
                                                    <TextField
                                                        value={template.testValue}
                                                        style={{ width: '100%' }}
                                                        onChange={(event) => {
                                                            changeTestValue(event.target.value, index)
                                                        }}
                                                        label={'Enter Value'} />
                                                </TableCell>

                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </div>

                </div>
                {/* Mapping Table ends */}

                {/* //Setup TimePicker */}

                <div className={styles.jobDetails}>
                    <div className={styles.flexContainer}>
                        <p className={styles.label}>Job Name: </p>
                        <TextField
                            value={jobName}
                            style={{ width: '40%' }}
                            onChange={(e) => { setJobName(e.target.value) }}
                            label={'Enter Name'} />
                    </div>
                    <div className={styles.flexContainer}>
                        <p className={styles.label}>From Email </p>
                        <Select
                            style={{ width: '40%', textAlign: 'left' }}
                            // native
                            value={selectedSenderId}
                            onChange={(event) => {
                                setSelectedSenderId(event.target.value)
                            }}
                        >
                            <MenuItem value={'Select'}>Select</MenuItem>
                            {
                                senderList.map((column) => {
                                    return <MenuItem key={column.id} value={column.from.email}>{column.from.email}</MenuItem>
                                })
                            }
                        </Select>
                    </div>

                    <div className={styles.flexContainer}>
                        <p className={styles.label}>Unsubscribe Group</p>
                        <Select
                            style={{ width: '40%', textAlign: 'left' }}
                            // native
                            value={selectedUnsubscribeGroup}
                            onChange={(event) => {
                                setSelectedUnsubscribeGroup(event.target.value)
                            }}
                        >
                            <MenuItem value={'Select'}>Select</MenuItem>
                            {
                                unsubscribeList.map((column) => {
                                    return <MenuItem key={column.id} value={column.id}>{column.name}</MenuItem>
                                })
                            }
                        </Select>
                    </div>

                    <div className={styles.flexContainer}>

                        <p className={styles.label} onClick={() => {
                            toggleDatePicker(true)
                        }}>Select Date and Time: </p>
                        <div style={{
                            textAlign: 'left'
                        }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                <DateTimePicker
                                    invalidDateMessage={invalidDateMessage}
                                    minDate={new Date()}
                                    // open={openDaterPicker}
                                    // clearable
                                    style={{
                                        // width: '55%',
                                        margin: 0,
                                        // marginLeft: '30px',
                                        marginTop: '-5px',
                                        // width: '40%',
                                        // border: 'red',
                                        // background: 'red'
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    value={selectedDate}
                                    onChange={(event) => {
                                        console.log("onchange date ", event)
                                        let currentDate = new Date();
                                        currentDate.setHours(currentDate.getHours() + 2);

                                        let newDate = event;

                                        if (newDate >= currentDate) {
                                            handleDateChange(event);
                                            toggleDatePicker(false)
                                            setInvalidDateMessage('')
                                        } else {
                                            handleDateChange(currentDate);
                                            setInvalidDateMessage('Time should be more 2 hours from now!')

                                        }
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'string',
                                    }}
                                    disabled={false}
                                    onOpen={() => toggleDatePicker(true)}
                                    onClose={(event) => {

                                    }}
                                    onAccept={() => { }}
                                    onError={() => { }} />
                            </MuiPickersUtilsProvider>
                            <p className={styles.dateError}>{invalidDateMessage}</p>
                        </div>


                    </div>



                </div>
            </div>
            <div style={{
                // width: '80%'
            }}>


                <Button variant="contained"
                    disabled={disableTestEmailBtn()}
                    color="primary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: '10px',
                        color: 'white',
                        padding: '3px 10px'
                    }}
                    onClick={() => {
                        toggleShowTestEmail(true)
                    }}
                >Send Test Email Now</Button>

                <Button variant="contained"
                    disabled={disableSubmitBtn()}
                    color="secondary"
                    style={{
                        textTransform: 'none',
                        marginTop: '20px',
                        marginLeft: '10px',
                        color: 'white',
                        padding: '3px 10px'
                    }}
                    onClick={submitEmailJob}
                >Submit Job</Button>
            </div>

            <TestEmail open={showTestEmail}
                handleClose={() => toggleShowTestEmail(false)}
                sendTestEmail={sendTestEmail}
            />
            <AlertDialog open={showAlert} messageText={messageText} closeAlert={() => toggleAlert(false)} />
            {
                showTemplate &&
                <TemplateViewModal templateId={templateId} toggleTemplateView={toggleTemplateView}
                    toggleAlert={toggleAlert}
                    setMessageText={setMessageText}
                />
            }

        </div>

    )
}

export default SendEmail;