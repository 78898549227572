import React, { useState, useEffect } from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import RefundAmountSplitup from './RefundAmountSplitup'
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { Modal } from 'react-bootstrap';
import './refund.css'
import CommonDropDwn from "./CommonDropDwn_1";
import customStyle from "../CSS/stylesheet_UHS";
import { patterns } from './CreatePayment';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h5" style={{ fontSize: '16px' }}>{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const useStyles = makeStyles({
    saveBtn: {
        fontSize: '13px',
        textTransform: 'none',
    },
    btnContiner: {
        marginTop: '20px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    table: {
        minHeight: '170px'
    },
    label: {
        // fontWeight: 'bolder',
        margin: 0,
        marginTop: '15px',
        paddingLeft: '16px',
        // paddingTop: '5px',
        marginBottom: '20px',
        fontSize: '15px'
    }
})
export default function RefundPaymentDialogue(props) {
    const classes = useStyles()
    const [amountBreakup, setAmountSplit] = useState({
        applicationFee: 0.00,
        creditCardFee: 0.00,
        monthlyShare: 0.00,
        uhfMonthlyFee: 0.00,
        healthToolFee:0.00,
        RXsimpleFee:0.00,
        totalAmountRefund: 0.00
    });
    const [reason, setReason] = useState('');
    const [mainReason, setMainReason] = useState('');
    const [showAmountBreakup, toggleshowAmountBreakup] = useState(false);

    const [feeType, setFeeType] = useState('')
    const [newAmt, setNewAmt] = useState({});
    const [confirmBtnState, disableConfrm] = useState(true);
    const [refundAdjstmentType, changeRefndAdjstType] = useState('ChargeBack');
    const [refndAdjstTransID, setRefndAdjstTransID] = useState('')
    const [enable, setEnable] = useState(false)
    const [refundReasons, setRefundReasons] = useState(['Can not afford', 'New coverage/other coverage', 'Medicare', 'Medicaid', 'Pre x', 'Deceased', 'Employer coverage', 'Pregnancy', 'Critical illness', 'Drug and alcohol rehab'])
    const [transactionIdError, setTransactionIdError] = React.useState(false);



    useEffect(() => {
        console.log('---props---- ', props.trasctionDataForRefund);
        let _amountSplit={}
        if(props.trasctionDataForRefund.healthToolAmount !== 0 && props.trasctionDataForRefund.rxsimpleShareAmount !== 0){
             _amountSplit = {
                applicationFee: props.trasctionDataForRefund.applicationFee,
                creditCardFee: props.trasctionDataForRefund.applicationFee,
                monthlyShare: props.trasctionDataForRefund.monthlyShare,
                uhfMonthlyFee: props.trasctionDataForRefund.uhfMonthlyFee,
                healthToolAmount : props.trasctionDataForRefund.healthToolAmount,
                rxsimpleShareAmount : props.trasctionDataForRefund.rxsimpleShareAmount,
                totalAmountRefund: props.trasctionDataForRefund.transactionAmount.toFixed(2),
                transactionAmount: props.trasctionDataForRefund.transactionAmount.toFixed(2),
            }
        }else if(props.trasctionDataForRefund.healthToolAmount !== 0){
             _amountSplit = {
                applicationFee: props.trasctionDataForRefund.applicationFee,
                creditCardFee: props.trasctionDataForRefund.applicationFee,
                monthlyShare: props.trasctionDataForRefund.monthlyShare,
                uhfMonthlyFee: props.trasctionDataForRefund.uhfMonthlyFee,
                healthToolAmount : props.trasctionDataForRefund.healthToolAmount,
                totalAmountRefund: props.trasctionDataForRefund.transactionAmount.toFixed(2),
                transactionAmount: props.trasctionDataForRefund.transactionAmount.toFixed(2),
            }
        }else if(props.trasctionDataForRefund.rxsimpleShareAmount !== 0 ){
            _amountSplit = {
                applicationFee: props.trasctionDataForRefund.applicationFee,
                creditCardFee: props.trasctionDataForRefund.applicationFee,
                monthlyShare: props.trasctionDataForRefund.monthlyShare,
                uhfMonthlyFee: props.trasctionDataForRefund.uhfMonthlyFee,
                rxsimpleShareAmount : props.trasctionDataForRefund.rxsimpleShareAmount,
                totalAmountRefund: props.trasctionDataForRefund.transactionAmount.toFixed(2),
                transactionAmount: props.trasctionDataForRefund.transactionAmount.toFixed(2),
            }
        }else{
            _amountSplit = {
                applicationFee: props.trasctionDataForRefund.applicationFee,
                creditCardFee: props.trasctionDataForRefund.creditCardFee,
                monthlyShare: props.trasctionDataForRefund.monthlyShare,
                uhfMonthlyFee: props.trasctionDataForRefund.uhfMonthlyFee,
                totalAmountRefund: props.trasctionDataForRefund.transactionAmount.toFixed(2),
                transactionAmount: props.trasctionDataForRefund.transactionAmount.toFixed(2),
            }
        }
        
        
        setAmountSplit(_amountSplit);
        toggleshowAmountBreakup(true);

        if (props.selectedPaymentMode == 'RefundAdjust') {
            setReason('');
            setMainReason('')
        }

    }, []);

    const handleClose = () => {
        props.closerefndpayment()
    };




    const changeReason = (e) => {
        let value = e.target.value;
        setReason(value);

        // checkForConfmBtn(feeType, value, refndAdjstTransID)

        // if (props.selectedPaymentMode == 'RefundAdjust') {
        //     checkForRefndAdjstConfm(amountBreakup.totalAmountRefund, value, refndAdjstTransID)

        // } else {
        //     checkForConfmBtn(feeType, value)
        // }


    }

    const selectReason = (e) => {
        setMainReason(e);
        checkForConfmBtn(feeType, e, refndAdjstTransID)
    }

    const toggleConfirmBtn = (_amount, _reason) => {
        disableConfrm(false)
        // let maxamount = getRefundAmount()
        // if (_amount && _reason.length > 0) {
        //     let amountValue = Number(_amount);
        //     if (amountValue < 0 || amountValue > Number(maxamount)) {
        //         disableConfrm(true)
        //     } else {
        //         disableConfrm(false)
        //     }
        // } else {
        //     disableConfrm(true)
        // }

    }

    // const getRefundAmount = () => {
    //     // return 10

    //     let maxRefundAmt = props.trasctionDataForRefund.transactionAmount;
    //     if (props.trasctionDataForRefund.refundAmount) {
    //         maxRefundAmt = props.trasctionDataForRefund.transactionAmount - props.trasctionDataForRefund.refundAmount;
    //         maxRefundAmt = Math.round(maxRefundAmt * 100) / 100
    //     }
    //     // Math.round((num + Number.EPSILON) * 100) / 100
    //     return maxRefundAmt

    // }

    const confirmRefund = () => {

        let _reason = reason;
        if (props.selectedPaymentMode == 'RefundAdjust') {
            let adjustLabel = 'Chargeback Adjustment'
            if (refundAdjstmentType == 'Adjustment') {
                adjustLabel = 'Refund Adjustment'
            }
            _reason = adjustLabel + ': ' + _reason
        }
        let data = {
            // reason: mainReason + ': ' + _reason,
            reason: `${mainReason}${_reason ? ": " : ""}${_reason}`,
            total: 0,
            displayAmount: 0,
            feeType: feeType,
            refndAdjstTransID: refndAdjstTransID,

        }
        console.log("feeType",amountBreakup)
        // if (props.trasctionDataForRefund.paymentNumber == 1) {
        //     if (feeType == 'ApplicationFee') {
        //         data.total = amountBreakup.applicationFee;
        //         data.displayAmount = amountBreakup.applicationFee;

        //     }else if(feeType == 'HealthToolFee') {
        //         data.total = amountBreakup.healthToolAmount;
        //         data.displayAmount = amountBreakup.healthToolAmount;
        //     }else if(feeType == 'RxSimplaShareFee') {
        //         data.total = amountBreakup.rxsimpleShareAmount;
        //         data.displayAmount = amountBreakup.rxsimpleShareAmount;
        //     }else if(feeType == 'ApplicationHealthtool') {
        //         data.total = parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.applicationFee);
        //         data.displayAmount = parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.applicationFee);
        //     }else if(feeType == 'ApplicationRxSimpleShare') {
        //         data.total = parseFloat(amountBreakup.rxsimpleShareAmount) + parseFloat(amountBreakup.applicationFee);
        //         data.displayAmount = parseFloat(amountBreakup.rxsimpleShareAmount) + parseFloat(amountBreakup.applicationFee);
        //     }else if(feeType == 'ApplicationHealthRxsimple') {
        //         data.total =parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount + amountBreakup.applicationFee);
        //         data.displayAmount = amountBreakup.healthToolAmount + parseFloat(amountBreakup.rxsimpleShareAmount) + parseFloat(amountBreakup.applicationFee);
        //     }else if(feeType == 'HealthToolRxSimpleshare') {
        //         console.log("total amt---",parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount))
        //         data.total =parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount);
        //         data.displayAmount = parseFloat (amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount);
        //     }
        //     else {
        //         data.total = amountBreakup.totalAmountRefund;
        //         data.displayAmount = amountBreakup.totalAmountRefund;
        //     }

        // }else {
        //     if(feeType == 'HealthToolFee') {
        //         data.total = amountBreakup.healthToolAmount;
        //         data.displayAmount = amountBreakup.healthToolAmount;
        //     }else if(feeType == 'RxSimplaShareFee') {
        //         data.total = amountBreakup.rxsimpleShareAmount;
        //         data.displayAmount = amountBreakup.rxsimpleShareAmount;
        //     } else if(feeType == 'HealthToolRxSimpleshare') {
        //         console.log("total amt---",parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount))
        //         data.total =parseFloat(amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount);
        //         data.displayAmount = parseFloat (amountBreakup.healthToolAmount) + parseFloat(amountBreakup.rxsimpleShareAmount);
        //     }else {
        //         data.total = amountBreakup.totalAmountRefund;
        //         data.displayAmount = amountBreakup.totalAmountRefund;
        //     }

        // }
            data.appFee = newAmt.appFee;
            data.monthlyFee = newAmt.monthlyFee;
            data.monthlyShare = newAmt.monthlyShare;
            data.total = newAmt.total;
            data.refundAmount = newAmt.refundAmount;
            data.displayAmount = newAmt.refundAmount;
        
       
        console.log('feeType::', feeType)
        props.confirmRefund(data)
    }

    const updateAmountBreakUp = (_feeType) => {

        console.log(_feeType)
        setFeeType(_feeType);
        checkForConfmBtn(_feeType, reason, refndAdjstTransID);
    }

    const updateRefundAmt = (amt) => {
        setNewAmt(amt)
    }

    const checkForConfmBtn = (_feeType, _reason, _refndAdjstTransID) => {

        if (props.trasctionDataForRefund.paymentNumber == 1) {


            if (_feeType == 'ApplicationFee' && amountBreakup.applicationFee > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }

            } else if (_feeType == 'TotalAmount' && amountBreakup.totalAmountRefund > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            } else if (_feeType == 'HealthToolFee' && amountBreakup.healthToolAmount > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }else if (_feeType == 'RxSimplaShareFee' && amountBreakup.rxsimpleShareAmount > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }else if (_feeType == 'ApplicationRxSimpleShare' && amountBreakup.rxsimpleShareAmount > 0 && amountBreakup.applicationFee > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }else if (_feeType == 'ApplicationHealthtool' && amountBreakup.healthToolAmount > 0 && amountBreakup.applicationFee > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }else if (_feeType == 'HealthToolRxSimpleshare' && amountBreakup.rxsimpleShareAmount > 0 && amountBreakup.healthToolAmount > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }else if (_feeType == 'ApplicationHealthRxsimple' && amountBreakup.rxsimpleShareAmount > 0 && amountBreakup.healthToolAmount > 0 && amountBreakup.applicationFee > 0 && _reason.length > 0) {

                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            }

            else {
                disableConfrm(true)
            }

        } else {
            if (amountBreakup.totalAmountRefund > 0 && _reason.length > 0) {
                // disableConfrm(false)
                if (props.selectedPaymentMode == 'RefundAdjust') {
                    if (_refndAdjstTransID.length > 0) {
                        disableConfrm(false)
                    } else {
                        disableConfrm(true)
                    }
                } else {
                    disableConfrm(false)
                }
            } else {
                disableConfrm(true)
            }

        }


    }

    const checkForRefndAdjstConfm = (amount, reason, transId, isError) => {
        if (amount > 0 && amount <= props.trasctionDataForRefund.transactionAmount && reason.length > 0 && transId.trim().length > 0 && !isError) {
            disableConfrm(false)
        } else {
            disableConfrm(true)
        }
    }

    const onAmountChangeRefndAdjust = (e) => {
        let value = e.target.value;
        let _amountSplit = {
            applicationFee: props.trasctionDataForRefund.applicationFee,
            creditCardFee: props.trasctionDataForRefund.creditCardFee,
            monthlyShare: props.trasctionDataForRefund.monthlyShare,
            healthToolFee : props.trasctionDataForRefund.healthToolFee,
            RXsimpleFee :props.trasctionDataForRefund.RXsimpleFee,
            uhfMonthlyFee: props.trasctionDataForRefund.uhfMonthlyFee,
            totalAmountRefund: value,
        }
        setAmountSplit(_amountSplit);
        checkForRefndAdjstConfm(value, mainReason, refndAdjstTransID)

    }



    const getAmountSplitupView = () => {
        if (showAmountBreakup) {




            if (props.selectedPaymentMode == 'RefundAdjust') {
                return (
                    <div className="refund-adjst">
                        <RefundAmountSplitup showAmountBreakup={showAmountBreakup} amountBreakup={amountBreakup} setEnable={(e) => setEnable(e)} paymentType={props.trasctionDataForRefund.type} updateAmountBreakUp={updateAmountBreakUp} updateRefundAmt={updateRefundAmt} selectedPaymentMode={'Refund'} paymentNumber={props.trasctionDataForRefund.paymentNumber} />




                    </div>
                )
            } else {
                return <RefundAmountSplitup showAmountBreakup={showAmountBreakup} amountBreakup={amountBreakup} setEnable={(e) => setEnable(e)} paymentType={props.trasctionDataForRefund.type} updateAmountBreakUp={updateAmountBreakUp} updateRefundAmt={updateRefundAmt} selectedPaymentMode={'Refund'} paymentNumber={props.trasctionDataForRefund.paymentNumber} />
            }
        }
        return null;
    }

    return (
        <Modal style={{marginTop: '3%'}} onClose={handleClose} aria-labelledby="customized-dialog-title" show={props.isOpen} maxWidth={"sm"} fullWidth={true}>
            <Modal.Header style={{alignItems: 'center'}}>
            <Typography variant="h5" style={{ fontSize: '16px' }}>Refund Payment</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Modal.Header>
            <Modal.Body dividers>
                <div className="refund-dialouge">
                    <p className="month-label">Payment number: <span className="month-no">{props.trasctionDataForRefund.paymentNumber}</span></p>
                    {/* <p className="msg">Please ensure this is the latest transaction. You should not refund for intervening ones.</p> */}
                </div>
                {getAmountSplitupView()}




                <div >

                    <div style={{
                        display: props.selectedPaymentMode == 'RefundAdjust' ? 'flex' : 'none'
                    }}>
                        <div>
                            <Radio
                                checked={refundAdjstmentType == 'ChargeBack'}
                                onChange={() => {
                                    changeRefndAdjstType('ChargeBack')
                                    setReason('');
                                    setMainReason('')
                                    disableConfrm(true)
                                    setTransactionIdError(false)
                                    setRefndAdjstTransID('')
                                }}
                                name="Chargeback Adjustment"
                                style={{
                                    color: '#35b8b9'
                                }}
                            />
                            <span style={{ fontSize: '13px' }}>Chargeback</span>
                        </div>
                        <div>
                            <Radio
                                checked={refundAdjstmentType == 'Adjustment'}
                                onChange={() => {
                                    changeRefndAdjstType('Adjustment');
                                    setReason('');
                                    setMainReason('')
                                    disableConfrm(true)
                                    setTransactionIdError(false)
                                    setRefndAdjstTransID('')
                                }}
                                name="Refund Adjustment"
                                style={{
                                    color: '#35b8b9'
                                }}
                            />
                            <span style={{ fontSize: '13px' }}>Refund</span>
                        </div>
                    </div>


                    <div className={classes.label} style={{
                        display: props.selectedPaymentMode == 'RefundAdjust' ? 'flex' : 'none',
                        alignItems: 'center'
                    }}>

                        <div style={{
                            width: '40%',
                            display: ' inline-block',
                            marginTop: '10px'
                            // marginTop: props.selectedPaymentMode == 'RefundAdjust' ? '10px' : '0'
                        }}>
                            Chargeback/Refund Transaction ID: </div>
                        <TextField

                            inputProps={{
                                style: {
                                    fontSize: '14px',
                                    paddingLeft:'10px'
                                }
                            }}

                            placeholder="Enter Transaction ID"
                            value={refndAdjstTransID}
                            style={{
                                width: '50%'
                            }}
                            error={transactionIdError} helperText={transactionIdError ? 'Enter a Valid Transaction ID' : ''}

                            onChange={(e) => {
                                let value = e.target.value.trim()
                                let isPatternMatched = value.match(patterns.amount);
                                setTransactionIdError(!isPatternMatched)
                                setRefndAdjstTransID(value);
                                checkForConfmBtn(feeType, reason, value)
                                checkForRefndAdjstConfm(amountBreakup.totalAmountRefund, mainReason, value, !isPatternMatched)
                            }}
                        // helperText={'Less than 300 $'}
                        />
                    </div>

                    <div className='refundReasonDiv'>
                        <div style={{
                            width: '45%',
                            display: ' inline-block',
                            // marginTop: props.selectedPaymentMode == 'RefundAdjust' ? '10px' : '0'
                        }}>
                            <CommonDropDwn setChild={selectReason} name={'RefundReason'} label={'Select Reason'} value={mainReason} disable={false} style={customStyle.dropDown} fieldType={'dropDwn'} helperMsg={'Select refund reason'} List={refundReasons} parentDetails={{ label: 'Refund_Reason' }}></CommonDropDwn>
                        </div>


                        <TextField
                            disabled={mainReason == ''}
                            variant="outlined"
                            label={'Enter Additional Data'}
                            inputProps={{
                                style: {
                                    fontSize: '14px'
                                },
                                maxLength: 200
                            }}
                            placeholder="Enter Additional Data"
                            value={reason}
                            style={{
                                width: '45%',
                                marginLeft: '40px'
                            }}
                            onChange={changeReason}
                            helperText={"Supports up to 200 characters."}
                            multiline
                            rows={3}
                            maxRows={3}
                        />

                    </div>
                </div>

                <div className={classes.btnContiner}> <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    // disabled={reason.length <= 0}
                    className={classes.saveBtn}
                    // startIcon={<MailOutlineIcon />}
                    onClick={confirmRefund}
                    disabled={confirmBtnState}
                >Confirm</Button></div>


            </Modal.Body>


        </Modal >);
}
