const
    configurations = {
        agentBaseUrl: process.env.REACT_APP_agentBaseUrl, //prod agent server back end URL
        enrollmentBaseUrl: process.env.REACT_APP_enrollmentBaseUrl,  //Prod enrollment back end URL
        auditBaseUrl: process.env.REACT_APP_auditBaseUrl, // prod transaction back end URL,
        csrBaseUrl: process.env.REACT_APP_csrBaseUrl,
        paymentBaseUrl: process.env.REACT_APP_paymentBaseUrl,
        REACT_APP_transaction_base_url: process.env.REACT_APP_transactionBaseUrl,
        REACT_APP_BASE_URL:process.env.REACT_APP_BASE_URL
    }

export default configurations;


