import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { toggleLoader } from '../../../store/slice/notificationSlice';
import Loader from '../../authentication/loader';

window.dashboard = 'yay';

const MemberDashboard = () => {
    const dispatch = useDispatch();
    const showLoader = useSelector((state) => state.notification.showLoader);
    const [dashboardURL, setDashboardURL] = useState('');
    useEffect(() => {
        getDashboardURL()



    }, [])

    const getDashboardURL = () => {
        dispatch(toggleLoader(true));
        let request = {
            "username": "regulator",
            "password": "##Infyadmin1"
        }

        // let requestURL = 'https://jc08o9dt14.execute-api.us-east-2.amazonaws.com';
        // let requestURL= 'https://apishareplus.carynhealth.com';
        let requestURL = 'https://2btt51qv59.execute-api.us-east-2.amazonaws.com';
        let apiKey = 'y29gwmfNNj4ALv9jE5Hzk3V3CBUsMOnaaexsS4fO';
        // let apiKey = 'lIIwjqkmZF3V5T2Mk8qYG3aUStltuWQAaPkpq9JL'

        Axios.post(requestURL + '/v1/login', request, {
            headers: {
                'x-api-key': apiKey,
                'Content-Type': 'application/json',
            }
        }).then((resp) => {
            let token = resp.data.data.id_token;
            // https://apishareplus.carynhealth.com
            // https://us-east-2.quicksight.aws.amazon.com/sn/dashboards/8f4d1ed1-b883-4368-8f1a-e5890af4a692

            // old 46111dcc-7715-4e62-ac75-7bf70a81c041
            let prodDashboardId = '13ebcddb-d02f-4edf-a78e-bd8bcf5b6c81';
            let devDashboardId = '8f4d1ed1-b883-4368-8f1a-e5890af4a692';
            let dashboardId = process.env.REACT_APP_MemberData_DashboardId;

            fetch(requestURL + '/v1/payable/get-quicksight-dashboard-url/?dashboard_id=' + dashboardId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                    'token': token
                }
            }).then((response) => response.json())
                .then((responseJson) => {

                    console.log(responseJson)
                    // this.embedDashboard(responseJson.EmbedUrl)
                    // console.log(embedURL)
                    setDashboardURL(responseJson.EmbedUrl)
                    embedDashboard(responseJson.EmbedUrl)
                })
        }).catch((err) => {
            dispatch(toggleLoader(false));
        })
    }

    const embedDashboard = (embedURL) => {
        const containerDiv = document.getElementById("embeddingContainer");


        let width = window.screen.width - 80;


        const options = {
            // replace this dummy url with the one generated via embedding API
            url: embedURL,
            container: containerDiv,
            scrolling: "no",
            height: "700px",
            width: width + 'px',
            footerPaddingEnabled: true
        };

        try {
            window.dashboard = QuickSightEmbedding.embedDashboard(options);
            window.dashboard.on("error", onDashboardError);
            window.dashboard.on("load", onDashboardLoad);
            // dispatch(toggleLoader(false));
        } catch (err) {
            console.log(err)
            dispatch(toggleLoader(false));
        }
        dispatch(toggleLoader(false));
    }

    const onDashboardError = (payload) => {
        dispatch(toggleLoader(false));
        console.log("Do something when the dashboard fails loading", payload);
    }

    const onDashboardLoad = (payload) => {
        dispatch(toggleLoader(false));
        console.log("Do something when the dashboard is fully loaded.", payload);
    }

    const getSheets = () => {
        debugger
        window.dashboard.getSheets((data) => {
            debugger
        });

    }

    const initiatePrint = () => {
        debugger
        window.dashboard.initiatePrint((data) => {
            debugger
        });
    }

    const testFn = () => {
        alert('hii')
    }


    return (
        <div>


            <div id="embeddingContainer">
            </div>
            <Loader showLoader={showLoader} />
        </div>
    )

}

export default MemberDashboard;