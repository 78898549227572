import React from 'react'
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';

export const ExportCSV = ({ csvData, fileName,disabled }) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        // <Button variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>Export</Button>
        <Button size="small" color="primary"
            disabled={disabled}
            onClick={() => { exportToCSV(csvData, fileName) }}
            variant="contained"
            startIcon={<CloudDownloadIcon />}
        > Report</Button>
    )
}