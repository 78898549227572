import React, { Component } from "react";
import { withRouter } from 'react-router-dom'
import { Auth } from "aws-amplify";
import Card from '@material-ui/core/Card';
import Login from './LoginForm'
import LoginOTPForm from './LoginOTPForm'
import ForgotPassword from './ForgotPassword';
import Loader from './loader'
import './style.css'
import axios from "axios";
import Configuration from "../../configurations";
import Cookies from 'universal-cookie';
import FirstTimePasswordChange from './FirstTimePasswordChange';

const cookies = new Cookies();

export class SignIn extends Component {
  constructor(props) {
    super(props);
    let emailToReset = sessionStorage.getItem('emailToReset');
    this.state = {
      username: "",
      password: "",
      signedin: false,
      confirmationCode: "",
      forgotPass: emailToReset ? true : false,
      sendVerification: false,
      verificationCode: '',
      sendMFA: false,
      user: {},
      errorMesssage: '',
      showLoader: false,
      notAuthorisedPerson : false,
      firstTimepwdRest: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleForgot = this.handleForgot.bind(this);
    this.handleSendVerification = this.handleSendVerification.bind(this);

    this.confirmSignIn = this.confirmSignIn.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

  }

  componentDidMount() {
    sessionStorage.removeItem('CLIENT_ID');
    sessionStorage.removeItem('CHAT_BOX_Id');
    document.body.classList.add('bodyColor');
  }
  componentWillUnmount() {
    document.body.classList.remove('bodyColor');
  }
  handleChange(e, val) {
    console.log('change')
    this.setState({
      [e.target.name]: e.target.value
    });
    if(val)
    this.props.history.push('/login')
  }

  async handleSubmit(e) {
    console.log('submit')
    e.preventDefault();
    this.toggleLoader(true)
    const { signedin, username, password, user } = this.state;
    this.setState({
      errorMesssage:''
    })
    //method will signin the user and return current user with session
    localStorage.setItem('email', username);
    const authUser = await Auth.signIn({
      username: username,
      password: password,
    }).catch(err => {
      // console.log(err);
      // alert(err.message)
      this.setState({
        errorMesssage: 'Incorrect username or password.',
        disableSiginBtn: false
      })
      this.toggleLoader(false)

    });
    this.toggleLoader(false)
    if (authUser) {

      if (authUser.challengeName === 'SMS_MFA' ||
        authUser.challengeName === 'SOFTWARE_TOKEN_MFA') {

        this.setState({
          user: authUser,
          sendMFA: true,
          disableSiginBtn: false
        })
      } else if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.setState({
          user: authUser,
          disableSiginBtn: false,
          firstTimepwdRest: true,
        })
      } else {
        this.setState({
          disableSiginBtn: false
        });
        this.props.setUserLogin(true);
        this.getToken();
        this.props.history.replace('/admin');
      }
    }
  }

  getToken=() => {
    let request = {
      "username": process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
      "password": process.env.REACT_APP_GETWAY_TOKEN_PASS
    }
    let innoOrganization = Buffer.from(`IP_AEC;${new Date()}`).toString('base64')
    axios.post(process.env.REACT_APP_BaseAPIURL + "api/v1/login", request, {headers:{
        'X-Tenant-Id': innoOrganization,
    }}).then(response => {
        if (response.data.message) { 
            console.log("failed to get token")
            alert(response.data.message)
    }
    else {
        let bearer = response['headers'].authorization
        var array = bearer.split("Bearer ")
        localStorage.setItem("innobearerToken", array[1]);
    }})
}
  handleForgot(e) {
    e.preventDefault();
    // const {forgotPass } = this.state;
    this.setState({
      forgotPass: true
    })

  };




  handleSendVerification(e) {
    e.preventDefault();
    const { username } = this.state;
    Auth.forgotPassword(username)
      .then(data => console.log(data))
      .catch(err => console.log(err));
    this.setState({
      sendVerification: true
    })
  }

  async confirmSignIn(e) {
    e.preventDefault();
    const { verificationCode, sendMFA, signedin, user } = this.state;
    this.toggleLoader(true);

    const loggedUser = await Auth.confirmSignIn(
      user,   // Return object from Auth.signIn()
      verificationCode,   // Confirmation code  
      "SMS_MFA"
      // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(() => {
      console.log('sign in confirm success')
      this.toggleLoader(false);
      this.props.setUserLogin(true);
      this.props.history.replace('/admin');
    }).catch(err => {
      console.log(err);
      this.toggleLoader(false);
      this.setState({
        errorMesssage: err.message
      })

      // alert(err.message)
    });



  }




  async handleLogout() {
    console.log('trying to logout')
    let curUser = await Auth.currentAuthenticatedUser();
    console.log(curUser)
    await Auth.signOut();
    localStorage.clear();
    sessionStorage.clear();
    curUser = await Auth.currentAuthenticatedUser();
    console.log(curUser)
  }

  toggleLoader = (value) => {
    this.setState({
      showLoader: value
    })
  }

 

  getVIew() {
    const { signedin, forgotPass, sendVerification, verificationCode, sendMFA, user, firstTimepwdRest } = this.state;
    if(!this.state.notAuthorisedPerson){
      if (forgotPass) {
        return (
            <ForgotPassword confirmSignIn={this.confirmSignIn} toggleLoader={this.toggleLoader}/>
        )
      }
      else if (sendMFA) {

        return (
            <LoginOTPForm user={user} handleChange={this.handleChange} confirmSignIn={this.confirmSignIn} errorMesssage={this.state.errorMesssage} />
        )
   

      }
      else if (signedin) {
        return (
            <Card style={{ padding: "20px", marginTop: "50px" }}>
              welcome you are signed in
              <button onClick={this.handleLogout}>logout</button>
            </Card>
        )

      } else if (firstTimepwdRest) {
        return (
            <FirstTimePasswordChange email={this.state.username} user={user} confirmSignIn={this.confirmSignIn} toggleLoader={this.toggleLoader} />
        )
      } else {
        return (

            <Login handleSubmit={this.handleSubmit} handleChange={this.handleChange} handleForgot={this.handleForgot} handleToggle={this.props.handleToggle} errorMsg={this.state.errorMesssage} disableSiginBtn={this.state.disableSiginBtn} />

        );
      }
    } else {
      return (
          <div>
            <div style={{ marginTop: '30px', width: '95.2%', marginLeft: '2.4%', marginRight: '2.4%',}}>
              <div style={{width : '55%', margin : '20%'}}>
                <h4>Oops! Something's not right.</h4>
                Please go back to the email and click on the link to self-enroll. If you're still having trouble, call us on (866) 638-9355.
              </div>
            </div>
          </div>
      )
    }

  }

  render() {
    return (
      <>
        {this.getVIew()}
        <Loader showLoader={this.state.showLoader} />

      </>
    )

  }
}

export default withRouter(SignIn);
