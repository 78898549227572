import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { toggleLoader } from '../../../store/slice/notificationSlice';
import Loader from '../../authentication/loader';
import Landing from './cognito-settings/Landing';
window.dashboard = '';

const MemberDashboard = () => {
    const dispatch = useDispatch();
    const showLoader = useSelector((state) => state.notification.showLoader);
    const [dashboardURL, setDashboardURL] = useState('');
    useEffect(() => {
        getDashboardURL()
    }, [])

    const getDashboardURL = () => {
        // let baseURL = process.env.REACT_APP_SHARED_MODULE_URL;
        // let token = localStorage.getItem('bearerToken');
        // let _commonURL = baseURL + 'cognito-settings?token=' + token;
        // setDashboardURL(_commonURL)
    }


    return (
        <div>
            <Landing/>
            <Loader showLoader={showLoader} />
        </div>
    )

}

export default MemberDashboard;