import React, { Component } from 'react'

import { CSVReader } from 'react-papaparse'
import { Button } from '@material-ui/core';
const buttonRef = React.createRef()

export default class ReaderCSV extends Component {
    constructor(props) {
        super(props)
    }
    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point 
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = (data, file) => {

        if (data && data.length > 0 && data[0].errors.length <= 0) {
            let columnTitles = data[0].data;
            columnTitles.unshift('Select');
            this.getBase64(file).then((dataBase64) => {
                this.props.setMemberDataSetBase64(dataBase64);
            });
            this.props.setMemberDataSet(data, file.name);
            this.props.setMemberDataColumns(columnTitles);
            console.log('::columnTitles::', columnTitles)
        } else {
            console.log('parse error')
        }
    }

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }



    handleOnError = (err, file, inputElem, reason) => {
        console.log(err)
    }

    handleOnRemoveFile = (data) => {
        console.log('---------------------------')
        console.log(data)
        console.log('---------------------------')
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e)
        }
    }

    render() {
        return (
            <CSVReader
                ref={buttonRef}
                onFileLoad={this.handleOnFileLoad}
                onError={this.handleOnError}
                noClick
                noDrag
                onRemoveFile={this.handleOnRemoveFile}
            >
                {({ file }) =>
                    (

                        <aside
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                // marginBottom: 10,
                                // width: '500px',
                                fontSize: '12px'
                            }}
                        >
                            {/* <button
                                type='button'
                                onClick={this.handleOpenDialog}
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    // width: '40%',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    padding: '10px'
                                }}
                            > 
                                  Load Member Data from CSV
                             </button>*/}

                                <Button variant="outlined"
                                    color="primary"
                                    style={{
                                        textTransform: 'none'
                                    }}
                                    onClick={this.handleOpenDialog}
                                >   Load Member Data from CSV</Button>

                          
                   
                            {/* <div
                                style={{
                                    borderWidth: 1,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    height: 30,
                                    lineHeight: 2.5,
                                    marginTop: 5,
                                    marginBottom: 5,
                                    paddingLeft: 13,
                                    paddingTop: 3,
                                    width: '60%'
                                }}
                            >
                                {file && file.name}
                            </div>
                            <button
                                style={{
                                    borderRadius: 0,
                                    marginLeft: 0,
                                    marginRight: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    background: '#fd4242',
                                    color: 'white',
                                    border: 'none'
                                }}
                                onClick={this.handleRemoveFile}
                            >
                                Remove
                              </button> */}
                        </aside>
                    )

                }
            </CSVReader>
        )
    }
}