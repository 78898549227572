import React, { Component } from 'react';
import '../../Assets/CSS/window.css';
import { Auth } from 'aws-amplify';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Badge from '@material-ui/core/Badge';
import AppsIcon from '@material-ui/icons/Apps';
import customStyle from '../../Assets/CSS/stylesheet_UHS';
import { Modules } from '../../App';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ListIcon from '@material-ui/icons/List';
import ExtensionIcon from '@material-ui/icons/Extension';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            openAccount: false,
            openDrawer: false
        }
    }

    handleClose = () => {
        this.setState({ openAccount: false, anchorEl: null })
    };

    logoutHandler = (event) => {
        this.setState({
            loaderShow: true
        });
        Auth.signOut();
        sessionStorage.clear();
        localStorage.clear();
        this.props.setUserLogin(false);
        this.props.history.replace('/login');
    }

    handleMenuClick = (event) => {
        this.setState({ openAccount: true, anchorEl: event.currentTarget })
    };

    getMenuIcon = () => {
        console.log("::::", this.props.pathName)
        if (this.props.pathName != Modules.Home) {
            if (this.props.pathName.includes('notification')) {
                return (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
                        this.setState({
                            openDrawer: true
                        })
                    }} >
                        <MenuIcon style={{ marginTop: '6px', marginRight: '6px', height: 25, width: 25, color: '#333' }} />
                    </IconButton>
                )
            } else {
                return (
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => {
                        this.props.goBack();
                    }} >

                        <ArrowBack style={{ marginTop: '6px', marginRight: '6px', height: 25, width: 25, color: '#333' }} />

                    </IconButton>
                )
            }

        }

    }


    render() {
        return (
            <div>
                <AppBar title="Admin Portal" position='static' style={{ backgroundColor: '#fff', marginBottom: '-28px' }}>
                    <Toolbar>
                        {this.getMenuIcon()}

                        <Grid xs={6} style={customStyle.HeaderRightWrp} item={true}>
                            {/* <img style={customStyle.HeaderRightLogo} src={require('../../../Assets/Images/UHS Logo.png')} /> */}
                            <img style={customStyle.innoLogo} src={require('../../Assets/Images/Logo.png')} />
                        </Grid>
                        <div className="spacer" />
                        {/* <NotificationsIcon style={customStyle.rightIcon} /> */}
                        <AccountCircle style={customStyle.rightIcon} onClick={this.handleMenuClick} />
                        <Menu keepMounted open={this.state.openAccount}
                            anchorEl={this.state.anchorEl} onClose={this.handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 110, horizontal: 80, }}
                            transformOrigin={{ vertical: 80, horizontal: "right", }}
                        >
                            <MenuItem onClick={this.logoutHandler}>
                                Sign Out
                        </MenuItem>
                        </Menu>
                        {/* <AppsIcon style={customStyle.rightIcon}/>            */}

                    </Toolbar>
                </AppBar>

                <Drawer anchor={'left'} open={this.state.openDrawer} onClose={() => {
                    this.setState({
                        openDrawer: false
                    })
                }}>
                    <div onClick={() => {
                        this.setState({
                            openDrawer: false
                        })
                    }}>
                        <List>
                            <ListItem button onClick={() => {
                                this.props.history.push('/notification/email');
                            }}>
                                <ListItemIcon style={{
                                    minWidth: '35px'
                                }}><MailIcon /></ListItemIcon>
                                <ListItemText primary={'Send Email'} />
                            </ListItem>
                            
                            <ListItem button onClick={() => {
                                this.props.history.push('/notification/email-history')
                            }}>
                                <ListItemIcon style={{
                                    minWidth: '35px'
                                }}><ListIcon /></ListItemIcon>
                                <ListItemText primary={'Email Calendar'} />
                            </ListItem>

                            <ListItem button onClick={() => {
                                this.props.history.push('/notification/email-template-list');
                            }}>
                                <ListItemIcon style={{
                                    minWidth: '35px'
                                }}><ExtensionIcon /></ListItemIcon>
                                <ListItemText primary={'Template Managment'} />
                            </ListItem>


                        </List>
                        <Divider />
                        <List>

                            <ListItem button onClick={() => {
                                this.props.history.push('/admin');
                            }}>
                                <ListItemIcon style={{
                                    minWidth: '35px'
                                }}><ArrowBack /></ListItemIcon>
                                <ListItemText primary={'Home'} />
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
            </div>
        )
    }

}
export default Header;