import React, { Component } from 'react';


export default class MainCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cardImage: props.cardImage
        }
    }
    componentDidMount() {
        this.setState({
            cardImage: this.props.cardImage
        })
    }
    cardClick = () => {
        this.props.loadComponent(this.props.moduleKey)
    }

    mouseOver = () => {
        this.setState({
            cardImage: this.props.cardHoverImage
        })
    }

    mouseOut = () => {
        this.setState({
            cardImage: this.props.cardImage
        })
    }
    render() {
        return (
            <div className="cardmarginleftright" onClick={this.cardClick} onMouseOver={this.mouseOver} onMouseLeave={this.mouseOut}>
                <div className="Card-BG  mt-3"> 
                    <img
                        // src={require('../../Assets/Images/encode_urls_active.svg')}
                        src={this.state.cardImage}
                        className="mt-3" style={{ height: '80px', width: '80px' }} />
                    <p className="captiontextdiv text-center mt-3">
                        {this.props.name}
                    </p>
                </div>
            </div>
        )
    }
}