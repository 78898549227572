import React, { useState, useEffect } from 'react';
import { Route, Switch, Router, Redirect, useRouteMatch, useLocation } from 'react-router-dom';
import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Modules } from '../../../App';
import SendEmail from './SendEmail';
import EmailTemplate from './EmailTemplates';
import TemplateView from './TemplateView';
import Loader from '../../authentication/loader';
import { useSelector } from 'react-redux';
import EmailHistoryView from './EmailHistoryView';
import TemplateDynamicValues from './TemplateDynamicValues'
import EmailTemplateList from './EmailTemplateList';


const useStyles = makeStyles(theme => ({

    root: {
        // width: '75%'
        // marginTop: '30px',
        padding: '10px 0px',
        background: '#fff'
    },


}));

const Notification = (props) => {
    const classes = useStyles();
    const showLoader = useSelector((state) => state.notification.showLoader);
    
    let { path, url } = useRouteMatch();
    let history = useHistory();

    let location = useLocation();
    // let params = useParams()
    useEffect(() => {
        // getEmailTemplates()
        console.log("::", path, "::url", url)
    }, []);

    const getEmailTemplates = () => {
        // Axios.get(SendGridRequestURL + 'templates?generations=dynamic', axiosConfig).then((resp) => {
        //     console.log(resp.data)
        //     setTemplates(resp.data.templates);

        // }).catch((err) => {
        //     console.log(err, 'failed to load templates')
        // })
    };

    const loadTemplate = (templateId) => {
        // history.push('/email-template-view/'+templateId)
    }

    
    return (
        <div style={{ width: '100%', overFlow: 'hidden' }}>
            <div className={classes.root}>

                <Switch>
                    <Route exact path={path}>
                        <Redirect to={path + '/email'} />
                    </Route>
                    <Route path={path + '/email'} component={SendEmail} />
                    <Route exact path={path + '/email-templates'} component={EmailTemplate} />
                    <Route path={path + '/email-history'} component={EmailHistoryView} />
                    <Route path={path + '/email-templates/:templateId'} component={TemplateView} />
                    <Route path={path + '/template-values/:templateId'} component={TemplateDynamicValues} />
                    <Route path={path + '/email-template-list'} component={EmailTemplateList} />
                </Switch>


            </div>

            <Loader showLoader={showLoader} />
           
        </div>
    )
}

export default Notification;