import React, { useEffect, useState } from 'react';
import moment from "moment";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
// import apiService from '../../utils/apiservice';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '@material-ui/core/IconButton';
import axios from 'axios'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
// import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
// import RefundPayment from './RefundPayment';
// import { DialogTitle } from './ViewHealthQuestions'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';
import RefundPayment from './RefundPayment';
import configurations from "../../../configurations";
import RefundPaymentDialogue from './RefundPaymentDialogue';
import PaymentConfirmation from './ConfirmBox';
import {Modal} from 'react-bootstrap'
import ProdLvlRefundDialogue from './ProdLvlRefundDialogue';
import ConfirmTermination from './ConfirmTermination';
import ConfirmPopup from './ConfirmPopup';

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650,
    },
    button: {
        marginLeft: '10px',
        textTransform: 'none',
        fontSize: '11px',
        marginBottom: '5px'

    },
});



const rows = [
    {
        id: 1234,
        amount: 500,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 111,
        amount: 100,
        status: 'Pending',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 222,
        amount: 200,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    }, {
        id: 333,
        amount: 1500,
        status: 'Approved',
        Date: '21-08-2020',
        isRefund: false,
        Coverage: '21-08-2020',
        type: 'Refund',
        description: 'some reason for Refund'
    }, {
        id: 444,
        amount: 5400,
        status: 'Failed',
        Date: '21-08-2020',
        isRefund: true,
        Coverage: '21-08-2020',
        type: 'Payment',
        description: ''
    },
];

export default function ViewTransactionHistory(props) {
    const classes = useStyles();
    const [transactionHistory, setTransactionHistory] = useState([]);
    const [trasctionDataForRefund, setTrasctionDataForRefund] = useState(false);
    const [showConfirm, toggleConfrm] = useState(false);
    const [showRefundPaymentVIew, toggleRefundPaymentView] = React.useState(false);
    const [enableRefund, toggleRefund] = React.useState(false);
    const [maxOfPayment, setMaxOfPayment] = React.useState(0);
    const [paymentRequest, setPaymentRequest] = useState({ amount: '' });
    const [transactionId, setTransactionId] = React.useState('');
    const [transactionIdError, setTransactionIdError] = React.useState(false);
    const [transactionDetail, setTransactionDetail] = React.useState([]);
    const [showTransactionDetail, toggleTransactionTableView] = React.useState(false);
    const [message, setMessage] = useState(null)
    const [paymentNumber, setPaymentNumber] = useState(0);
    const [createdDate, setCreatedDate] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0)
    const [isTotalChecked, setIsTotalChecked] = useState(false)
    const [isTerminatedMember, setIsTerminatedMember] = useState(JSON.parse(localStorage.getItem("isTerminatedMember")))

    useEffect(() => {
        loadTransactionHistory();
        console.log('------ ------ ', props);
    }, [isTerminatedMember]);

    const loadTransactionHistory = () => {
        props.globaLoader(true);
        // let searchValue = 'swapnalees@perscitussln.com';
        // let searchValue = 'anshula80@yopmail.com';
        // let searchValue = props.userData.email;
        let searchValue = props.userData.memberIdSource
        console.log('::memberIdSource::', props.userData.memberIdSource)

        axios.get(configurations.paymentBaseUrl + 'transaction/transactionHistory?searchValue=' + searchValue + '&searchKey=source')
            // apiService.get(process.env.REACT_APP_BASE_URL + 'v6/transaction/transactionHistory?searchValue=' + searchValue + '&searchKey=email', true)
            .then((response) => {
                console.log(response)
                if (response && response.data && response.data.response) {
                    setTransactionHistory(response.data.response)
                    setMaxOfPayment(Math.max.apply(Math, response.data.response.map(function(o) { return o.paymentNumber; })))
                } else {
                    setTransactionHistory([])
                }

                props.globaLoader(false);
            }).catch((error) => {
                setTransactionHistory([])
                props.globaLoader(false);
            });
    }
    const handleClose = () => {
        props.toggleRegistrationStatus(false)
    };
    const closerefndpayment = () => {
        toggleRefundPaymentView(false)
    };

    const closeConfirm = () => {
        toggleConfrm(false)
    }

    const accept = (value) => {
        toggleConfrm(false);
        makeRefund(paymentRequest, value);
    }

    const makeRefund = (data, isTerminate) => {
        let id = localStorage.getItem("selectedID")
        let sub_id = localStorage.getItem("sub_id")
        if (trasctionDataForRefund) {
            props.globaLoader(true);
            let resp = ''
            axios.post(`${process.env.REACT_APP_paymentService}transactions/${id}/components`, data, true)
                .then((response) => {
                    resp = response.data.message;
                    props.globaLoader(false);
                    setTrasctionDataForRefund(false);
                    if (response.data.data.authorizationMessage == "FAILED") {
                        setMessage(`Refund Error: <b>${response.data.data.responseText}</b>`)
                      } else {
                        if (isTerminate) {
                            axios.post(`${process.env.REACT_APP_enrollmentBaseUrl}/enrollment/terminate/${sub_id}`, {terminatedReason: "REFUND"})
                            .then(res => {
                                console.log('res: ', res);
                            })
                            .catch(err => {
                                console.log('err: ', err);
                            })
                        }
                        setMessage(`Refund of <b>$${response.data.data.amount.slice(1)}</b> against payment number <b>${paymentNumber}</b> has been processed successfully. Refund Transaction Id is <b>${response.data.data.transactionId}</b>`)
                      }
                }).catch((error) => {
                    props.globaLoader(false);
                    if (error.response && error.response.data && error.response.data.errors) {
                        const errorMessage = error.response.data.errors[0];
                        setMessage(`Refund Error: <b>${errorMessage}</b>`);
                    } else {
                        console.error("Refund Error:", error);
                        setMessage(`Refund Error: <b>An error occurred while processing the refund.</b>`);
                    }
                });
            closerefndpayment();
        } else {
            closerefndpayment();
        }
    }

    const onRefundClick = (transaction, paymentNumber, createdDate) => {
        setTrasctionDataForRefund(transaction);
        setPaymentNumber(paymentNumber)
        setCreatedDate(createdDate)
        toggleRefundPaymentView(true)
    }

    const confirmRefund = (data) => {
        console.log('data--',data)
        toggleConfrm(true);
        setPaymentRequest(data);
    }
    const closeRefund = () => {
        setMessage(null);
        loadTransactionHistory();
      }

    const getTransactionMsg = (transaction) => {
        let description = ''
        if (transaction.transactionStatus == 'FAIL') {
            let reason = transaction.refundDescription
            if (transaction.reason) {
                try {
                    reason = JSON.parse(transaction.reason).error_message
                }
                finally {
                    return reason
                }
            }
        }

        if (transaction.reason && transaction.reason.length > 0) {
            description = transaction.reason
        }
        description = description + ' ' + (transaction.refundDescription ? transaction.refundDescription : '')
        return description;
    }

    const showRefundBtn = (transaction) => {
        let amountToRefnd = transaction.transactionAmount - transaction.refundAmount;
        let hasAmountToRefund = amountToRefnd && amountToRefnd > 0;

        if (transaction.indicatorFlag == 'PAYMENT' && hasAmountToRefund && transaction.authorizationMessage == 'APPROVED') {
            return <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                // startIcon={<ContactMailIcon />}
                onClick={() => onRefundClick(transaction)}

            >
                Refund
            </Button>

        }
        return null;


    }
    return (
        <div className={classes.root} style={{ overflowX: "auto" }}>

            <Table className={`${classes.table} transaction-table-list`} size="small" aria-label="a dense table" hidden={transactionHistory.length <= 0}>
                <TableHead>
                    <TableRow styl={{
                        background: '#dedede'
                    }}>
                        <TableCell colSpan={2} align="center">Date</TableCell>
                        <TableCell style={{
                            // maxWidth: '100px'
                        }}>Transaction ID</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell align="left">Payment Type</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Status</TableCell>
                        <TableCell align="left"> Month</TableCell>
                        {/* <TableCell align="left">Status</TableCell> */}
                        <TableCell align="left" style={{ maxWidth: '300px' }}>Description</TableCell>
                        <TableCell align="left">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {transactionHistory.map((transaction, index) => (
                        <Row memberDetails={props.userData} key={transaction.transactionId} row={transaction} maxOfPayment={maxOfPayment} onRefundClick={onRefundClick} index={index} />

                        // <TableRow key={index}>
                        //     <TableCell align="left">{transaction.createdDate}</TableCell>
                        //     <TableCell >
                        //         {transaction.transactionId ? transaction.transactionId : ' - '}
                        //     </TableCell>
                        //     <TableCell align="left">{transaction.indicatorFlag ? transaction.indicatorFlag : '      - '}</TableCell>
                        //     <TableCell align="left">{transaction.type}</TableCell>
                        //     <TableCell align="left">{transaction.transactionAmount ?
                        //         '$ ' + transaction.transactionAmount : '      - '} </TableCell>
                        //     <TableCell align="left">{
                        //         transaction.authorizationMessage ? transaction.authorizationMessage : '      - '
                        //     }</TableCell>

                        //     <TableCell align="left">{transaction.paymentNumber}</TableCell>

                        //     {/* <TableCell align="left">{
                        //         transaction.transactionStatus
                        //     }</TableCell> */}
                        //     <TableCell align="left" style={{ maxWidth: '300px' }}>{
                        //         getTransactionMsg(transaction)
                        //     }</TableCell>

                        //     {
                        //         enableRefund &&
                        //         <TableCell align="left">{showRefundBtn(transaction)}</TableCell>
                        //     }


                        // </TableRow>
                    ))}
                </TableBody>
            </Table>
            {
                showRefundPaymentVIew &&
                <ProdLvlRefundDialogue
                    isOpen={showRefundPaymentVIew}
                    closerefndpayment={() => { toggleRefundPaymentView(false) }}
                    confirmRefund={confirmRefund}
                    trasctionDataForRefund={trasctionDataForRefund}
                    selectedPaymentMode={props.selectedPaymentMode}
                    paymentNumber={paymentNumber}
                    createdDate={createdDate}
                    totalAmount={(v) => setTotalAmount(v)}
                    setIsTotalChecked={setIsTotalChecked}
                    showConfirm={showConfirm}
                    closeConfirm={closeConfirm}
                    paymentRequest={totalAmount}
                    memberDetails={props.userData}
                    accept={accept}
                    isTotalChecked={isTotalChecked}
                    isTerminatedMember={isTerminatedMember}
                // selectedPaymentMode={'Refund'}

                />
                }
            <Modal style={{zIndex: 1500}} size="md" show={message} onHide={(event) => setMessage(null)} centered backdrop='static'>
                    <Modal.Header style={{ backgroundColor: '#33afb0', padding: '10px' }} >
                        <Modal.Title style={{color:'white'}} className="h6">Message</Modal.Title>

                        <IconButton style={{
                            float: 'right',
                            width: '25px',
                            height: '25px',
                            color:'white'
                            // marginTop: '5px'
                        }} onClick={(event) =>
                            closeRefund()
                        }>
                            <CloseIcon />
                        </IconButton>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: '200px'}}>
                        <div style={{ height: 'auto', overflowX: 'hidden', overflowY: 'auto', fontSize:"18px", color: '#000' }}>
                            <p dangerouslySetInnerHTML={{ __html: message }}></p>
                        </div>
                   </Modal.Body>
                   <Modal.Footer>
                    <Button style={{ backgroundColor: '#3f51b5', color: '#fff' }} onClick={() => closeRefund()}>Ok</Button>
                   </Modal.Footer>
                </Modal>
                <p hidden={transactionHistory.length > 0}>No Data Found</p>
        </div >
    );
}

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset'
        },
    }
})

function Row(props) {
    const { row, rowIndex, onRefundClick } = props
    const [open, setOpen] = React.useState(false)
    const classes = useRowStyles()
    const [menuOptions, setMenuOptions] = useState(['Refund'])
    const [message, setMessage] = useState(null)

    const [modalShow, setModalShow] = React.useState(false);

    const selectMenu = (index, dataIndex, rowData) => {
        localStorage.setItem("selectedID", rowData.transactionId)
        if(index === 0){
            axios.get(`${process.env.REACT_APP_paymentService}transactions/${rowData.transactionId}/components`)
            .then(res => {
                console.log('res---- ', res);
                onRefundClick(res.data.data, row.paymentNumber, row.createdDate)
            })
            .catch(err => {
            })
        }
      }

    const getDate = (date) => {
        const isDateValid = moment(date)['_isValid'];
        if (isDateValid) {
            return moment(date).format('MMMM D, YYYY');
        }
        return date;
    }
    console.log("Row----", props)

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const floatconversion = (x) => {
        return x.toFixed(2)
    }

    //function takes in two dates and add the dash between them to show the continuation
    const combineDate = (x, y) => {
        if (x == y) { return x }

        const z = (x + '-' + y)
        return z


    }
    const theme = createMuiTheme({
        MuiTableCell: {
            paddingLeft: '30px',
        }
    });



    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <TableRow className={'rowTable ' + classes.root}>
                    <TableCell className="rowTableCell">
                        <IconButton style={{
                            color: '#000'
                        }} aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                            {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row" className="rowcellcolor rowTableCell"  >
                        {getDate(row.createdDate)}
                    </TableCell>
                    <TableCell component="th" scope="row" className="rowcellcolor rowTableCell"  >
                        {row.transactionId != null ? row.transactionId : '0'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.indicatorFlag != null ? row.indicatorFlag : 'NA'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.type != null ? row.type : 'NA'}
                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        ${row.transactionAmount != null ? row.transactionAmount : 'NA'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.authorizationMessage != null ? row.authorizationMessage : 'NA'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                        {row.paymentNumber != null ? row.paymentNumber : '0'}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                    {row.reason != null ? row.reason : ""} {row.refundDescription != null ? row.refundDescription : "NA"}

                    </TableCell>
                    <TableCell align="left" className="rowcellcolor rowTableCell">
                    <span >
                                <PopupState variant="popover" popupId="demo-popup-menu" >
                                    {(popupState) => (
                                        <React.Fragment>
                                            <IconButton {...bindTrigger(popupState)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu {...bindMenu(popupState)} onClick={popupState.close}
                                                getContentAnchorEl={null}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                transformOrigin={{
                                                    vertical: -10,
                                                    horizontal: 30
                                                }}>
                                                {
                                                    row &&
                                                    menuOptions.map((option, index) => {
                                                        return <MenuItem key={index} value='view' onClick={(e) => selectMenu(index, rowIndex, row)} disabled={(index == 0 && (props.maxOfPayment != row.paymentNumber || row.paymentNumber == 0))} >{option}</MenuItem>
                                                    })
                                                }
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>

                            </span>
        
       </TableCell>
                    

                </TableRow>









                {/* The Following table row is used to for the information of the dropdown rows that explain the EOS */}
                <TableRow>
                    <TableCell style={{ padding: 0 }} colSpan={12}>
                        <Collapse in={open} timeout="auto" component="tr" unmountOnExit style={{ backgroundColor: '#f6f6f6' }}>
                            <Box>
                                {/* <Table className="collapseTableContainer" size="small" aria-label="purchases">
                 <TableHead> */}
                                <TableRow>
                                <TableCell align="left" className="innerTh" style={{ width: '200px' }}>
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '317px' }}>
                    Dental Amount
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '317px' }}>
                    Core Plan Amount
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '382px' }}>
                    Enrollment Fee
                  </TableCell>
                  <TableCell align="left" className="innerTh" style={{ width: '317px' }}>
                  Reference Transaction ID
                 </TableCell>
                                    {/* <TableCell align="left" className="innerTh" style={{ width: '382px' }}>
                                        Health Tools
                   </TableCell>
                                    <TableCell align="left" className="innerTh" style={{ width: '341px' }}>
                                        RXSimpleShare
                   </TableCell> */}





                                </TableRow>
                                {/* </TableHead> */}

                                <TableBody>
                                    {/* {row.inlineData.map((item) => ( */}
                                    <TableRow
                                        style={{
                                            background: '#fff'
                                        }}
                                    // key={item.checkNumber}
                                    >


<TableCell align="left" className="innerTh">
                   </TableCell>
                   <TableCell align="left" className="innerTh">
                     {
                         <>${row.dentalAmount !=null ? row.dentalAmount : '0' }</>
                       
                     }
                   </TableCell>
                   <TableCell align="left" className="innerTh">
                     {
                         <>${row.monthlyShare !=null ? row.monthlyShare : '0' }</>
                       
                     }
                   </TableCell>

                   <TableCell align="left" className="innerTh">
                     {
                        <>${row.applicationFee !=null ?row.applicationFee :'0'}</>
                     }
                   </TableCell>
                   <TableCell align="left" className="innerTh">
                     {
                        <>{row.referenceTransactionId !=null ?row.referenceTransactionId :'N/A'}</>
                     }
                   </TableCell>

                                        {/* <TableCell align="left" className="innerTh">
                                            {
                                                <>${row.healthToolAmount != null ? row.healthToolAmount : '0'}</>
                                            }
                                        </TableCell>

                                        <TableCell align="left" className="innerTh">
                                            <>${
                                                row.rxsimpleShareAmount != null ? row.rxsimpleShareAmount : '0'
                                            }
                                            </>
                                        </TableCell> */}







                                        {/* <TableCell align="left" className="innerTh">
                                      {row.refundAmount}
                                   </TableCell> */}
                                        {/* <TableCell align="left" className="innerTh">
                       {row.refundDescription}
                     </TableCell> */}


                                    </TableRow>
                                    {/* ))} */}
                                </TableBody>

                                {/* </Table> */}
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
                {/* <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    data={row}
                /> */}
            </ThemeProvider>
        </React.Fragment>
    )
}