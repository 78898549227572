import React, { Component } from "react";
import Header from "../Commonscreens/Header";
import { CSVReader } from "react-papaparse";
import { makeStyles,withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import MaterialTable from 'material-table';
import MaterialTableDemo from './CsvTable';
import customStyleUHS from '../../Assets/CSS/stylesheet';
import DeleteIcon from '@material-ui/icons/Delete';
const buttonRef = React.createRef();
const globalerror=false;
export default class UploadCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csvData: null,
      uploadbtn: true,
      tableerror:false,
      validation:false,
    };
  }

  

  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = (data) => {
    // console.log("handleOnFileLoad---------------------------");
    // console.log(data);
    var len=data.length;
    data.splice(len-1, 1);
    // console.log("length  is==",len);
    this.setState({ csvData: data ,uploadbtn:false});
    console.log(
      "handleOnFileLoad state---------------------------",
      this.state.csvData[1].data[2] );
  };

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  

  deleteRow(key){
    console.log("delste keys is==",key)

    var data = this.state.csvData;
     data.splice(key, 1);
     console.log("after delete json data", data)
     this.setState({ csvData: data })
  }
  handleBack = () => {
    this.props.onClick();
}
  

  render() {
    // console.log("msg is==",this.state.tableerror)
    return (
      <div class="uplaodcsv">
        <Header />

{
  this.state.uploadbtn
  ? <div class="uploadcsv_middlecontainer">
  <CSVReader
    ref={buttonRef}
    onFileLoad={this.handleOnFileLoad}
    onError={this.handleOnError}
    noClick
    noDrag
    onRemoveFile={this.handleOnRemoveFile}
  >
    {({ file }) => (
      <aside
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: -10,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={this.handleOpenDialog}
          style={{outlineWidth:'0px',fontSize:'18px'}}
        >
          Upload List
        </Button>

        <div
          class="uploadcsv_filename"
        >
          {file && file.name}
        </div>
      </aside>
    )}
  </CSVReader>
</div>
  :
  <div class="uploadcsv_table_container border">
  <table class="table">

  {
              this.state.csvData && this.state.csvData.map((data,index)=>
              <>
                {
                  index==0
                  ?<thead class="table_head_color">
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Id</th>
                    <th scope="col">Mobile No</th>
                    <th scope="col">Clinet ID</th>
                    <th scope="col">Association ID</th>
                    <th scope="col">Broker ID</th>
                    <th scope="col">Error</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                  :
                 
                      <tbody>
                        {
                          data.data[0]=="" || data.data[1]=="" || data.data[2]=="" || data.data[3]=="" || data.data[4]==""||
                          data.data[5]=="" ||   data.data[6]==""
                          ?
                          <tr style={{backgroundColor:'rgb(255,231,231)'}}>
                          <th scope="row" >{index}</th>
                          <td >{data.data[0]}</td>
                          <td>{data.data[1]}</td>
                          <td>{data.data[2]}</td>
                          <td>{data.data[3]}</td>
                          <td>{data.data[4]}</td>
                          <td>{data.data[5]}</td>
                          <td>{data.data[6]}</td>
                                                    
                          <td class="table_error" >
                          <span style={{marginRight:'2px'}}>
                             Invalid
                            </span>
                        

                            <span >
                              {data.data[0]==''||data.data[1]==''?' name,':null}
                            </span>
                          <span >
                            {data.data[3]==''?'mobile no.,':null}
                            </span>
                            <span >
                            {data.data[2]==''? 'email Id,':null}
                            </span>
                            <span >
                            {data.data[4]==''?' client Id,':null}
                            </span>
                            <span >
                            {data.data[4]==''?' Association Id,':null}
                            </span>
                            <span >
                            {data.data[5]==''?' Broker Id ':null}
                            </span>
                            </td>
                          <td onClick={()=>this.deleteRow(index)}> <DeleteIcon /></td>
                        </tr>
                          :
                          <tr>
                          <th scope="row">{index}</th>
                          <td>{data.data[0]}</td>
                          <td>{data.data[1]}</td>
                          <td>{data.data[2]}</td>
                          <td>{data.data[3]}</td>
                          <td>{data.data[4]}</td>
                          <td>{data.data[5]}</td>
                          <td>{data.data[6]}</td>
                          <td class="table_error_green">OK</td>
                          {/* <td class="">Valid Data</td>  */}
                          <td onClick={()=>this.deleteRow(index)}> <DeleteIcon /></td>
                        </tr>
                        }
                    
                      </tbody>
                     
                }
              </>
              )
  }
  
</table> 
            <div class="border uploadcsv_button_container">
              {
                         
                          this.state.csvData&&(this.state.csvData[1].data[0]==''||
                          this.state.csvData[1].data[1]==''||
                          this.state.csvData[1].data[2]==''||
                          this.state.csvData[1].data[3]==''||
                          this.state.csvData[1].data[4]==''||
                          this.state.csvData[1].data[5]==''||
                          this.state.csvData[1].data[6]==''
                          )
                          ||this.state.uploadbtn
                          ?
                              <CSVReader
                                ref={buttonRef}
                                onFileLoad={this.handleOnFileLoad}
                                onError={this.handleOnError}
                                noClick
                                noDrag
                                onRemoveFile={this.handleOnRemoveFile}
                              >
                                {({ file }) => (
                                  <aside
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      // marginBottom: -10,
                                    }}
                                  >
                                    <Button
                                      variant="contained" color="primary" style={{marginRight:'10px',backgroundColor:'#fb6647',outlineWidth:'0px'}}
                                      onClick={this.handleOpenDialog}
                                      
                                    >
                                      Re-Upload List
                                    </Button>
                                  </aside>
                                )}
                              </CSVReader>    
                              
                            : 
                          <Button variant="contained" color="primary" style={{marginRight:'10px',backgroundColor:'#fb6647',outlineWidth:'0px'}}>
                            CREATE ACCOUNT
                          </Button>
        }

      <Button variant="contained" color="primary"
      style={{backgroundColor:'#f7c25e',outlineWidth:'0px',marginRight:'10px'}}
      >
        CANCEL
      </Button>
      <Button style={{backgroundColor:'#f7c25e'}}  color="primary" variant="contained" onClick={()=>this.handleBack()}>Back To Home</Button>
      </div>
  </div>
}
       
      </div>
    );
  }
}
