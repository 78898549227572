import React from 'react';
import { withStyles } from "@material-ui/core/styles";
// import {connect} from "react-redux";
import { Auth } from "aws-amplify";
import Header from '../Commonscreens/Header';
import AdminHome from "./Home";
import axios from "axios";
// import configurations from "../../../configurations";
// import Loader from "../../loader";


class RootComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderShow: false,
            userLoggedIn: false,
            // agentId : "",
            // clientId : "",
            // associationId : "",
            // clientName : "",
            agentName: ''
        }
    }

    componentDidMount() {
        this.setState({
            loaderShow: true
        });

        // this.setState({ agentName: 'Thor odiinson', userLoggedIn: true, })

        // Auth.currentAuthenticatedUser()
        //     .then((user) => {
        //         console.log(user)
        //         let data = this.parseJwt(user.signInUserSession.idToken.jwtToken);
        //         console.log(data, user)
        //         this.setState({ agentName: data.name, userLoggedIn: true, })
        //         localStorage.setItem('email', user.attributes.email);
        //     }).catch((err) => {
        //         sessionStorage.setItem('isLogged', false);
        //         console.log('from root')
        //         this.props.history.push("/login");
        //         this.setState({
        //             // loaderShow: false,
        //             userLoggedIn: false
        //         })
        //     });
    }

    parseJwt = (id_token) => {

        let base64Url = id_token.split('.')[1];

        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

        let jsonPayload = decodeURIComponent(

            atob(base64)

                .split('')

                .map(function (c) {

                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)

                })

                .join('')

        );

        let token = JSON.parse(jsonPayload);
        return token;
    };

    render() {
        return (
            <div>
                {/* {
                    this.state.userLoggedIn &&
                   
                } */}

                <div>
                    {/* {
                            this.state.loaderShow ? <Loader></Loader> : ''
                        } */}
                    {/* <Header ></Header> */}
                    <AdminHome />
                </div>

            </div>
        )

    }
}

export default RootComponent;