import React, { useState, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import configurations from "../../../configurations";
import axios from 'axios';
import AmountSplitUp from './AmountSplitup'

const PaymentFormFields = (props) => {

    const [refundvalue, setrefundvalue] = useState(' - ');
    const [paymentType, setPaymentType] = useState('ACH');
    const [updatedAmountBreakup, setUpdateAmountBreakup] = useState(false)

    const getControl = (control, controlIndx) => {
        if (control.type == 'DropDown') {
            return (

                <FormControl variant="filled" style={{ width: '80%' }}>
                    <InputLabel id="demo-simple-select-filled-label">{control.label}</InputLabel>
                    <Select
                        value={control.value}
                        onChange={(e) => { changeValue(e, controlIndx) }}
                        style={{ textAlign: 'left' }}
                        required={true}
                        disabled={props.disableAll}
                    >
                        {
                            control.fields.map((item) => {
                                return <MenuItem value={item.value}>{item.label}</MenuItem>
                            })
                        }

                    </Select>
                </FormControl >

            )

        } else if (control.type == 'TextWithBtn') {
            return (
                <div style={{
                    textAlign: 'left',
                    paddingLeft: '10%',
                    display: 'flex',
                    // justifyContent: 'space-between'
                }}>

                    <TextField disabled={props.disableAll} variant="filled" style={{ width: '55%' }} value={control.value} label={control.label} onChange={(e) => { changeValue(e, controlIndx) }} required={true} error={control.hasError} helperText={control.hasError ? 'Enter a Valid ' + control.label + '.' : ''} />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small" style={{
                            marginLeft: '10px',
                            marginTop: '20px',
                            textTransform: 'none',
                            height: '30px',
                            fontSize: '11px'
                        }}
                        // startIcon={<MailOutlineIcon />}
                        onClick={() => { setrefundvalue('$ 100') }}
                    // disabled={disableSaveBtn}
                    >Get Refund Amount</Button>
                </div>

            )
        } else if (control.type == 'ReadOnly') {
            return (
                <div style={{
                    background: 'rgba(0, 0, 0, 0.13)',
                    border: 0,
                    margin: 0,
                    display: "inline-flex",
                    padding: 0,
                    position: 'relative',
                    width: '80%',
                    height: '55px',
                    borderRadius: '5px',
                    alignItems: 'center',
                    paddingLeft: '15px'
                }} >
                    {/* <p style={{ margin: 0 }}>{control.label}: <span style={{ fontWeight: 'bold' }}>{control.value ? '$ ' + control.value : ' - '}</span></p> */}
                    <p style={{ margin: 0 }}>{control.label}: <span style={{ fontWeight: 'bold' }}>{control.value}</span></p>

                </div>
            )
        } else {
            let helperText = control.helperText ? 'Enter a Valid ' + control.helperText : 'Enter a Valid ' + control.label;
            let showHelperText = control.hasError ? helperText : ''


            return (
                <TextField disabled={props.disableAll} variant="filled" style={{ width: '80%' }} value={control.value} label={control.label} onChange={(e) => { changeValue(e, controlIndx) }} required={true} error={control.hasError} helperText={showHelperText} onBlur={(e) => transactionIdOnBlur(control)} onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                        console.log('enter pressed')
                        e.target.blur()
                        // getEMPDetails()
                    }
                }} />
            )
        }
    }

    const transactionIdOnBlur = (control) => {
        if (control.key == 'transactionId') {
            getTransactionDetails(control.value)
            console.log(control.value)
        }
    }

    const getTransactionDetails = (transactionId) => {
        // addRefundNoteToCSR()
        if (transactionId.length <= 0) {
            return false;
        }

        let apiURL = configurations.paymentBaseUrl + 'transaction/transactionHistory?searchKey=transactionId&searchValue=' + transactionId;
        props.toggleLoader(true);
        axios.get(apiURL).then((resp) => {
            console.log(resp.data)
            props.toggleLoader(false);
            if (resp && resp.data.code == 200) {
                if (resp.data.response.length > 0) {
                    // let paymentType = resp.data.response[0].type;
                    setPaymentType(resp.data.response[0].type)
                }

            }

        }).catch((err) => {
            props.toggleLoader(false);
        });;
    }


    const changeValue = (event, controlIndx) => {

        let value = event.target.value;
        props.changeValue(value, controlIndx)
    }
    const updateAmountBreakUp = (breakupDetails) => {
        let _updatedAmountBreakup = {
            cardmerchantFee: breakupDetails.cardmerchantFee,
            total: breakupDetails.total,
            totalAmountAll: breakupDetails.totalAmountAll,
            applicationFee: breakupDetails.applicationFee,
            uhfMonthlyFee: breakupDetails.uhfMonthlyFee,
            monthlyShare: breakupDetails.monthlyShare,
            addOns: breakupDetails.addOns
        }

       
        // console.log(_updatedAmountBreakup)
        setUpdateAmountBreakup(_updatedAmountBreakup);
    }

    const onFormSubmit = (event) => {
        event.preventDefault();
        console.log(props.amountBreakup)
        // let cardmerchantFee = updatedAmountBreakup.cardmerchantFee;
        let total = updatedAmountBreakup.total;
        let totalAmountAll = updatedAmountBreakup.totalAmountAll;
        let _updatedAmountBreakup = updatedAmountBreakup
        // debugger
        props.makePaymentClick(props.amountBreakup, total, totalAmountAll, _updatedAmountBreakup)
    }

    return (
        <form onSubmit={onFormSubmit} className="payment-fields">
            <Grid container spacing={3} >

                {
                    props.fields.map((item, indx) => {
                        return (
                            <Grid key={indx} item xs={6}>
                                {getControl(item, indx)}
                            </Grid>
                        )
                    })

                }

                <div className='split-up-contain'>
                    <AmountSplitUp showAmountBreakup={props.showAmountBreakup} amountBreakup={props.amountBreakup} paymentType={paymentType} updateAmountBreakUp={updateAmountBreakUp} selectedPaymentMode={props.selectedPaymentMode} />
                </div>


                <div style={{
                    width: '100%',
                    marginTop: '10px',
                    marginBottom: '10px'
                }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        style={{
                            textTransform: 'none',
                        }}
                        // startIcon={<MailOutlineIcon />}
                        onClick={() => {
                            console.log('back to home clicked')
                            props.clearAllFields();
                            props.onClick();
                        }}
                    // disabled={disableSaveBtn}
                    >Back To Home</Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                        style={{
                            marginLeft: '15px',
                            textTransform: 'none',
                        }}
                        // startIcon={<MailOutlineIcon />}
                        onClick={(e) => {
                            // props.makePaymentClick()
                        }}
                        disabled={props.disableAll}
                    >Make Payment</Button>



                </div>
            </Grid>
        </form>
    )
}

export default PaymentFormFields;