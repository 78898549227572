import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
    const [open, setOpen] = React.useState(false);
    React.useEffect(() => {
        console.log('pros.open::',props.open)
        setOpen(props.open)
    }, [props.open])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.closeAlert()
    };

    return (
        <div>

            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                disableBackdropClick={true}
            >
                {/* <DialogTitle disableTypography={true} id="alert-dialog-title">{"Alert"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{
                        minWidth:'300px'
                    }}>
                        {
                            props.messageText
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <Button onClick={handleClose} color="primary" contained style={{
                        textTransform: 'none'
                    }}>
                        Okay
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
