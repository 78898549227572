import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles} from '@material-ui/core/styles';
import customStyle from '../../Assets/CSS/stylesheet';

const CustomTextField = withStyles(
    customStyle.textField,
)(TextField);

export default class CommonTextField extends Component{
    constructor(props){
       super(props)
       this.state={
           isValid:false,
       }
    }

    onChange(event){
     var val = event.target.value.trim();
     this.props.setval(this.props.name,event.target.value)
    }

    render() {
        
        return (
            <CustomTextField
                       error={this.state.isValid}
                       label={this.props.label}
                       name={this.props.name}
                       variant="filled"
                       autoComplete='off'
                       value={this.props.value===''&&this.state.isValid?this.state.value:this.props.value}
                       style={this.props.style}
                       helperText= {(this.props.value === '' || this.state.isValid ) ? this.state.errorText:''}
                       onChange={(event) => this.onChange(event)}
                       disabled={this.props.disable}
                       required={this.props.reqFlag}
                        InputLabelProps={{style: {color:this.state.isValid?'#FA1515':''}}}
                    //    inputProps={{
                    //        maxLength: this.props.length,
                    //    }}
            />
        );
    }
}