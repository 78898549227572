import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        minWidth: '400px',
        borderBottom: '1px solid #80808057',
        padding: '10px 24px',
        fontWeight: 500
    }

}))
export default function ConfirmTermination(props) {
    const [open, setOpen] = useState(false);
    const [modes, setModes] = useState(["Refund AND Terminate", "Refund but DO NOT Terminate"])
    const [mode, setMode] = useState("Refund AND Terminate")
    console.log('--==--== ', props.paymentRequest);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        props.handleClose()
    };


    const disagree = () => {
        props.handleClose()
    }
    const accept = () => {
        if (mode == "Refund AND Terminate") {
            props.accept(true)
        } else {
            props.accept()
        }
    }
    const classes = useStyles();

    const getMsg = () => {
        switch (props.selectedPaymentMode) {
            case 'RefundAdjust':
                return 'Adjust'
            case 'AccountOnFile':
                return 'Charge'
            case 'Refund':
                return 'Refund'
            default:
                return 'Refund'
        }
    }

    const onChange = (event) => {
        let value = event.target.value
        setMode(value);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <h6 className={classes.dialogTitle}>Refund Confirmation</h6>
                <DialogContent>
                    {
                        props.paymentRequest >= 0 &&
                        <DialogContentText id="alert-dialog-description" style={{
                            color: 'black'
                        }}>
                            Are you sure want to {getMsg().toLowerCase()} <span style={{ fontWeight: 'bold' }}>${Number(props.paymentRequest).toFixed(2)}</span> to <span style={{ fontWeight: 'bold' }}>{props.memberDetails.firstName} {props.memberDetails.lastName}</span>?<br />
                            How would you like to process this refund?
                        </DialogContentText>
                    }

                    <FormControl component="fieldset" className={classes.formControl} >
                        <RadioGroup aria-label="Payment mode" name="paymentmode" onChange={onChange} className={classes.paymentChoose} value={mode} >
                            {
                                modes.map(mode => (
                                    <FormControlLabel className={[classes.label, classes.labelActive]} value={mode} control={<Radio />} label={mode} />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={disagree} color="secondary" style={{ textTransform: 'none' }}>
                        Cancel
                    </Button>
                    <Button onClick={accept} color="primary" style={{ textTransform: 'none' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}