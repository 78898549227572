export const passwordCheck = {

    chechForNumbers: (string) => {
        var matches = string.match(/\d+/g);
        let value = matches != null ? true : false;
        return value;

    },

    checkForUpperCase: (string) => {
        var matches = string.match(/[A-Z]/);
        let value = matches != null ? true : false;
        return value;

    },

    checkForLowerCase: (string) => {
        var matches = string.match(/[a-z]/);
        let value = matches != null ? true : false;
        return value;
    },

    checkForSymbols: (string) => {
        var symbols = new RegExp(/[^A-Za-z0-9]/);
        let value = symbols.test(string) ? true : false
        return value;
    },

    checkPwdLength: (string) => {        
        let value = string.length > 7 ? true : false;
        return value;
    }
}