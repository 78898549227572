const awsConfig = {
    // identityPoolId: 'ap-south-1:62c69269-14c1-4213-9fd8-3011bcd1833c',
    // region: 'ap-south-1',
    // userPoolId: 'ap-south-1_lYRXJB955',
    // userPoolWebClientId: '2lflj5i9hesfe0cvqhofjopfpr'

    // "aws_project_region": "us-east-2",
    // "aws_cognito_identity_pool_id": "us-east-2:46bfd497-7c61-41d8-8260-2396dea4a5cf",
    // "aws_cognito_region": "us-east-2",
    // "aws_user_pools_id": "us-east-2_Y0chhWCeX",
    // "aws_user_pools_web_client_id": "3r57m2vh8qv1844it8pg36abk7",
    // "oauth": {}

    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": process.env.REACT_APP_aws_cognito_identity_pool_id,
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": process.env.REACT_APP_aws_user_pools_id,
    "aws_user_pools_web_client_id": process.env.REACT_APP_aws_user_pools_web_client_id,
    "oauth": {}
}


export default awsConfig;