import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'
import { SendGridRequestURL, sendGridAxiosConfig } from './sendGridConfig';
import { toggleLoader } from '../../../store/slice/notificationSlice';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './notification.module.scss';

const TemplateView = (props) => {
    const dispatch = useDispatch()
    const [template, setTemplate] = useState(null);
    let params = useParams()
    useEffect(() => {
        getEmailTemplate()
    }, []);

    const getEmailTemplate = () => {


        let templateId = props.templateId
        dispatch(toggleLoader(true));

        /**
         * Code changes for defect fix BP-1261
         */
        Axios.get(SendGridRequestURL + 'templates/' + templateId, sendGridAxiosConfig).then((resp) => {

            let html_content = resp.data.versions.length > 0 ? resp.data.versions[0].html_content : '';
            resp.data['html_content'] = html_content;
            console.log(resp.data)
            setTemplate(resp.data);
            dispatch(toggleLoader(false));

        }).catch((err) => {
            console.log(err, 'failed to load templates')
            dispatch(toggleLoader(false));
            props.handleClose()
            props.setMessageText('Template content not found!')
            props.toggleAlert(true)
        })
    };


    return (
        <div>

            {template &&
                <div>
                    <p className={styles.title}>{template.name}</p>
                    <div style={{
                        // height: '400px',
                        width: '100%',
                        overflow: 'auto'
                    }} dangerouslySetInnerHTML={{ __html: template.html_content }} />
                </div>
            }

            {
                !template &&
                <div style={{
                    minWidth: '400px',
                    height: '100px'
                }}>Loading ...</div>
            }

        </div>
    )
}

export default TemplateView;