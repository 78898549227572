import React from 'react';
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: '8px 8px'
    },
    closeButton: {
        position: 'absolute',
        width: '24px',
        height: '24px',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    button: {
        marginLeft: '10px',
        textTransform: 'none',
        fontSize: '11px',
        marginBottom: '5px'

    }
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="p">{children}</Typography>

            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);


export default function EmailJobStatusTable(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        props.closeModal(false);
    };
    console.log("props.emailJobStats::", props.emailJobStats)
    return (
        <div>

            <Dialog maxWidth="lg" onClose={handleClose} aria-labelledby="customized-dialog-title" open={true}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Email Job Stats
                </DialogTitle>
                <DialogContent dividers>
                    {
                        props.emailJobStats.length > 0 ?
                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow style={{
                                            background: '#efefef'
                                        }}>
                                            <TableCell >Status</TableCell>
                                            <TableCell>From</TableCell>
                                            <TableCell>To</TableCell>
                                            <TableCell>Open Count</TableCell>
                                            {/* <TableCell>clicks_count</TableCell> */}
                                            <TableCell>Time</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.emailJobStats.map((data, index) => (
                                            <TableRow key={index}>
                                                <TableCell >
                                                    {data.status.toUpperCase()}
                                                </TableCell>
                                                <TableCell >
                                                    {data.from_email}
                                                </TableCell>
                                                <TableCell >
                                                    {data.to_email}
                                                </TableCell>
                                                <TableCell >
                                                    {data.opens_count}
                                                </TableCell>
                                                <TableCell >
                                                    {
                                                        moment(data.last_event_time).format('YYYY-MM-DD HH:mm A')
                                                    }
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            :
                            <div >
                                <p style={{
                                    minWidth: '500px',
                                    textAlign: 'center'
                                }}>No Stats Available!</p>
                            </div>
                    }


                </DialogContent>

            </Dialog>
        </div>
    );
}