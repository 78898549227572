export default{
    copyBtn : {
        root: {
            width: '120px',
            height: '31px',
            backgroundColor: '#fb6647',
            color: '#ffffff',
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#e1e1e3',
                color: '#ffffff'
            },
        },
    },
    genrateBtn : {
        root: {
            width: '330px',
            height: '31px',
            marginLeft:'auto',
            backgroundColor: '#f7c25e',
            color: '#00000',
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#e1e1e3',
                color: '#ffffff'
            },
        },
    },
    multipleAgentBtn : {
        root: {
            width: '330px',
            height: '31px',
            marginLeft:'auto',
            backgroundColor: '#f7c25e',
            color: '#00000',
            // color:'#101010',
            textTransform:'none',
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#e1e1e3',
                color: '#ffffff'
            },
        },
    },
    genrateBtn1 : {
        root: {
            width: '350px',
            height: '31px',
            marginRight:'auto',
            backgroundColor: '#f7c25e',
            color: '#00000',
            '&:hover': {
                backgroundColor: '#420045',
                boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#e1e1e3',
                color: '#ffffff'
            },
        },
    },
    HomeContainer:{
        width:'100%',
        padding:'2%',
        marginLeft:'2%'
    },
    HomeMAinChild:{
        width:'96%',
        height:'30%',
        display:'flex',
        flexDirection:'column',
        backgroundColor:'#ffffff',
        justifyContent:'center',
        alignItems:'center',
        paddingTop:'50px',
        paddingBottom:'50px',
    },
    HomeTextWrp:{
        width:'224px',
        // marginLeft:'300px',
    },
    HomeTextContiner:{
        display:'flex',
    },
    urlWrp:{
        display:'flex',
        width:'100%',
        marginTop:'2%',
        marginLeft:'32%'
    },
    HomeLoginUrlWrp:{
        width:'560px',
        maxHeight: '60px',
        overflowX: 'hidden',
        overflowY: 'auto' ,
        border:'1px solid #c0c1c2',
        "overflow-wrap": "break-word",
        padding:'8px',
        borderRadius:5,
        fontSize: 13,
        color: '#19191d',
        fontfamily: 'Roboto',
        // marginLeft:'-12%',
        // marginLeft:'-24%',
        // marginTop:'5%'

    },
    urngenBtnwrp:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        width:'55%',
        marginTop:'3%',
    },
    formControlStyle:{
        width:'110%' ,marginTop:'-1px',
        // marginRight:'16px',
        // marginRight:'14px',
        // marginLeft:'-172px',display:'flex'
    },
    searchByStyle1:{
        border:'1px solid #cccccc',
        fontFamily:'Roboto',
        fontSize:'15px',
        width:'100px',
        // backgroundColor:'#f7f7f7',
        outline : 'none'
    },
    formControlStyle:{
        width:'110%' ,marginTop:'-1px',
        // marginRight:'16px',
        // marginRight:'14px',
        // marginLeft:'-172px',display:'flex'
    },
    formControlStyle1:{
        width:'100%' ,
        // marginTop:'-50px',
        // marginTop:'-3px',
        // marginRight:'9px',
        //  marginLeft:'-2%',
        marginright:'87px',
        // marginLeft:'-5%',
        paddingRight:'2%',
        marginLeft:'9%',
        display:'flex'
    },
    searchByStyle:{
        width:'100%',marginRight:'63px',
        textAlign:'left',fontSize:'14px',
        // marginTop:'23px'
        // paddingLeft:'6px'
    },
    textFieldStyle:{
        // marginLeft:'8px',
        marginTop:'-50px',
        // width:'34%',
        width:'90%',
        marginRight:'6%',
        marginLeft:'16%'
    },
    crudBtnAgent : {
        root: {
            backgroundColor: '#fb6647',
            color: '#ffffff',
            width:'50px',
            height:'50px',
            boxShadow:"none",
            '&:hover': {
                // backgroundColor: '#420045',
                backgroundColor: '#fb6647',
                // boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#e1e1e3',
                color: '#ffffff'
            },
        },
    },
}