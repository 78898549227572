import React, { Component } from 'react';
import moment from 'moment'
export default class Footer extends Component {
    render() {
        return (
            <div className=" text-center Copyright-2020-Universal-Health-Fellowship-All" style={{marginTop:'-15px'}}>
                Copyright © {moment().format("YYYY")} Innovative Partners. All rights reserved.
            </div>
        )
    }
}

