import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import moment from "moment";
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Axios from 'axios';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useSelector, useDispatch } from 'react-redux'; import { setEmailTemplates, toggleLoader } from '../../../store/slice/notificationSlice';
import { loginWithPromise } from '../../../store/slice/authSlice'
import TemplateViewModal from './TemplateViewModal';
import AlertDialog from '../../Commonscreens/AlertDialog';
import ConfirmBox from '../../Commonscreens/ConfirmBox';
import TablePagination from '@material-ui/core/TablePagination';
import SearchBar from "material-ui-search-bar";
import { compareValues } from '../../../helper/utils';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
    button: {
        marginLeft: '10px',
        textTransform: 'none',
        fontSize: '11px',
        marginBottom: '5px'

    }
});

const TEMPLATE_TYPE = {
    ADD: '021',
    EDIT: '001',
    INACTIVE: '024',
    ACTIVE: '025'
}

export default function EmailTemplateList() {
    const dispatch = useDispatch()
    let history = useHistory();
    const classes = useStyles();
    const [templateList, setTemplateList] = useState([]);
    const [showTemplate, toggleTemplateView] = useState(false);
    const [showAlert, toggleAlert] = useState(false);
    const [messageText, setMessageText] = useState('Email job created successfully.');
    const [templateId, setTemplateId] = useState(null);
    const [confirmationBoxDetails, setConfirmBoxDetails] = useState({
        message: '',
        open: false,
        handleClose: () => { },
        accept: () => { }
    });

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [activeSort, setActiveSort] = useState('updatedTime');
    const [order, setOrder] = useState('desc');
    const [searched, setSearched] = useState("");

    const mainTemplateList = useRef([])

    useEffect(() => {
        getEmailTemplates()
    }, [])


    const handleChangePage = (event, newPage) => {
        console.log('newpage...', newPage)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const getEmailTemplates = () => {
        dispatch(toggleLoader(true));

        loginWithPromise().then((token) => {
            Axios.get(process.env.REACT_APP_csrBaseUrl + 'csrportal/getemailtemplates', {
                headers: {
                    Authorization: token
                }
            }).then((resp) => {

                let data = resp.data;
                data.sort(compareValues('updatedTime', 'desc'));
                console.log(data)
                setTemplateList(data);
                mainTemplateList.current = data;

                dispatch(toggleLoader(false));
            }).catch((err) => {
                console.log(err, 'failed to load templates');
                dispatch(toggleLoader(false));
            });
        })

    };

    const confirmToggleStatus = (template) => {
        let status = template.status == 'ACTIVE' ? 'Inactive' : 'Active';
        // status = status.charAt(0) + status.substr(1).toLocaleLowerCase();

        let message = 'Are you sure want to make template ' + status + '?';
        setConfirmBoxDetails({
            message: message,
            open: true,
            handleClose: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false })
            },
            accept: () => {
                setConfirmBoxDetails({ ...confirmationBoxDetails, open: false });
                if (template.status == 'ACTIVE') {

                } else {

                }
                toggleTemplateStatus(template)
                // removeEmailTemplate(item)
            }
        })
    }

    const toggleTemplateStatus = (template) => {
        dispatch(toggleLoader(true));

        let request = {
            "templateId": template.templateId,
            "templateName": template.templateName,
            "createdBy": template.createdBy || 'Admin',
            "updatedBy": localStorage.getItem('email') || 'Admin',
            "status": template.status == 'ACTIVE' ? 'INACTVE' : 'ACTIVE',
            "maintainenceType": template.status == 'ACTIVE' ? TEMPLATE_TYPE.INACTIVE : TEMPLATE_TYPE.ACTIVE,
            "dynamicFields": ['test']

        }

        Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/emailtemplate', request, {
            headers: {
                Authorization: sessionStorage.getItem('csrtoken')
            }
        }).then((resp) => {
            console.log(resp.data)
            dispatch(toggleLoader(false));
            toggleAlert(true);
            getEmailTemplates()
            setMessageText('Template Updated Successfully!')

        }).catch((err) => {
            console.log(err, 'failed to update templates');
            toggleAlert(true);
            setMessageText('Failed to Update Template!')
            dispatch(toggleLoader(false));
        })
    }


    // const removeEmailTemplate = (template) => {
    //     dispatch(toggleLoader(true));

    //     let request = {
    //         "templateId": template.templateId,
    //         "templateName": template.templateName,
    //         "createdBy": template.createdBy || 'Admin',
    //         "updatedBy": localStorage.getItem('email') || 'Admin',
    //         "status": "ACTIVE",
    //         "maintainenceType": TEMPLATE_TYPE.DELETE,
    //         "dynamicFields": ['test']

    //     }

    //     Axios.post(process.env.REACT_APP_csrBaseUrl + 'csrportal/emailtemplate', request, {
    //         headers: {
    //             Authorization: sessionStorage.getItem('csrtoken')
    //         }
    //     }).then((resp) => {
    //         console.log(resp.data)
    //         dispatch(toggleLoader(false));
    //         toggleAlert(true);
    //         getEmailTemplates()
    //         setMessageText('Template Removed Successfully!')

    //     }).catch((err) => {
    //         console.log(err, 'failed to update templates');
    //         toggleAlert(true);
    //         setMessageText('Failed to Remove Template!')
    //         dispatch(toggleLoader(false));
    //     })
    // }

    const createSortHandler = (type) => {

        console.log(type);
        let sortedList = templateList.slice(0);

        let _order = order == 'asc' ? 'desc' : 'asc'; toString()
        if (activeSort != type) {
            _order = 'asc';
        }
        setOrder(_order);
        setActiveSort(type)

        sortedList.sort(compareValues(type, _order));
        setTemplateList(sortedList);
        setPage(0);
        // console.log(sortedList)
    }

    const requestSearch = (searchedVal) => {
        const filteredRows = mainTemplateList.current.filter((row) => {
            return row.templateName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setTemplateList(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, templateList.length - page * rowsPerPage);

    return (
        <div>
            <div style={{
                padding: '10px',
                textAlign: 'right',
                paddingRight: '20px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between'
            }}>

                <SearchBar
                    style={{
                        border: '1px solid gray',
                        minWidth: '350px',
                        height: '35px',
                    }}
                    value={searched}
                    onChange={(searchVal) => requestSearch(searchVal)}
                    onCancelSearch={() => cancelSearch()}
                />

                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    className={classes.button}
                    style={{
                        height: '30px',
                        marginBottom: 0
                    }}
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={e => {
                        history.push('/notification/template-values/new')
                    }}

                >
                    Add New Template
                        </Button>
            </div>
            <div>


                <TableContainer component={Paper}>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow style={{
                                background: '#efefef'
                            }}>
                                <TableCell >
                                    <TableSortLabel
                                        active={activeSort == 'createdTime'}
                                        direction={order}
                                        onClick={() => createSortHandler('createdTime')}
                                    >Created Date</TableSortLabel>
                                </TableCell>
                                <TableCell>Template Name</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell >
                                    <TableSortLabel
                                        active={activeSort == 'updatedTime'}
                                        direction={order}
                                        onClick={() => createSortHandler('updatedTime')}
                                    >Updated Date</TableSortLabel>
                                </TableCell>
                                <TableCell align="center" >
                                    <TableSortLabel
                                        active={activeSort == 'status'}
                                        direction={order}
                                        onClick={() => createSortHandler('status')}
                                    >Action</TableSortLabel>
                                </TableCell>
                                {/* <TableCell align="center">Action</TableCell> */}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* {templateList.map((template, index) => (
                                 */}
                            {templateList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((template, index) => (

                                <TableRow key={index}>
                                    <TableCell >
                                        {moment(template.createdTime).format('YYYY-MM-DD')}
                                    </TableCell>
                                    <TableCell scope="row">
                                        {template.templateName}
                                    </TableCell>
                                    <TableCell scope="row">
                                        {template.updatedBy}
                                    </TableCell>
                                    <TableCell scope="row">
                                        {template.updatedTime ? moment(template.updatedTime).format('YYYY-MM-DD') : '-'}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div style={{
                                            textAlign: 'left'
                                        }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.button}
                                                startIcon={<VisibilityIcon />}
                                                onClick={e => {
                                                    setTemplateId(template.templateId);
                                                    toggleTemplateView(true);
                                                }}

                                            >
                                                View
                                </Button>

                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                className={classes.button}
                                                startIcon={<EditIcon />}
                                                onClick={e => {
                                                    history.push('/notification/template-values/' + template.templateId)
                                                }}

                                            >
                                                Edit
                                </Button>

                                            <FormControlLabel
                                                style={{
                                                    margin: '5px',
                                                    minWidth: '130px',
                                                    background: template.status == 'ACTIVE' ? 'antiquewhite' : 'none',
                                                    borderRadius: '5px',
                                                    paddingLeft: '8px',
                                                }}
                                                control={
                                                    <Switch
                                                        checked={template.status != 'INACTIVE'}
                                                        onChange={() => {
                                                            confirmToggleStatus(template)
                                                        }}
                                                        name="checkedB"
                                                        color="primary"
                                                    />
                                                }
                                                label={template.status != 'INACTIVE' ? 'Active' : 'Inactive'}
                                                labelPlacement="start"
                                            />

                                            {/* <Button
                                            variant="contained"
                                            color="secondary"
                                            size="small"
                                            className={classes.button}
                                            startIcon={<DeleteOutlineIcon />}
                                            onClick={e => {
                                                confirmRemove(template)
                                            }}

                                        >
                                            {template.status == 'INACTIVE' ? 'Make Active' : 'Make Inactive'} Remove
                                </Button> */}
                                        </div>
                                    </TableCell>

                                </TableRow>
                            ))
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 33 }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={templateList.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
            {
                showTemplate &&
                <TemplateViewModal templateId={templateId} toggleTemplateView={toggleTemplateView}
                    toggleAlert={toggleAlert}
                    setMessageText={setMessageText}
                />
            }
            <AlertDialog open={showAlert} messageText={messageText} closeAlert={() => toggleAlert(false)} />
            <ConfirmBox
                open={confirmationBoxDetails.open}
                message={confirmationBoxDetails.message}
                accept={confirmationBoxDetails.accept}
                handleClose={confirmationBoxDetails.handleClose}
            />

        </div>

    );
}