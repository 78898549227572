export default {
    grid_parent : {
        root : {
            flexGrow : 1,
            margin : '10px'
        },
        control: {
            padding: '10px',
        },
        paper : {
            height : '120px',
            width : '150px',
            backgroundColor : '#686868',
            fontWeight : 'bold',
            fontSize : '18px',
            textAlign : 'center'
        }
    },

    crudBtnAgent : {
        root: {
            backgroundColor: '#304d63',
            color: '#ffffff',
            width:'50px',
            height:'50px',
            boxShadow:"none",
            '&:hover': {
                // backgroundColor: '#420045',
                backgroundColor: '#304d63',
                // boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#304d63',
                color: '#ffffff'
            },
        },
    },
    crudRefreshBtn : {
        root: {
            backgroundColor: '#41b5c2',
            color: '#ffffff',
            width:'50px',
            height:'50px',
            boxShadow:"none",
            '&:hover': {
                // backgroundColor: '#420045',
                backgroundColor: '#41b5c2',
                // boxShadow: '0 4px 8px 0 #eae8db, 0 2px 4px 0 #eae8db',
            },
            '&:focus':{
                outline:'none'
            },
            '&:disabled': {
                backgroundColor: '#41b5c2',
                color: '#ffffff'
            },
        },
    },

    statusBg:{
        // width: '72px',
        // width:'240%',
        width:'max-content',
        height: '30px',
        borderRadius: '4px',
        backgroundColor: '#feedec',
        textAlign:'center',
        // padding:'5px'
        paddingLeft:'5px',
        paddingRight:'5px',
        paddingTop:'5px'
    },
    statusBg1:{
        // width: '72px',
        // width:'240%',
        width:'max-content',
        height: '30px',
        borderRadius: '4px',
        backgroundColor: '#edf6ee',
        textAlign:'center',
        paddingLeft:'5px',
        paddingRight:'5px',
        paddingTop:'5px'
        // padding:'5px'
    },
    statusBgTwoLine:{
        // width: '72px',
        // width:'250%',
        width:'max-content',
        height: '30px',
        borderRadius: '4px',
        backgroundColor: '#feedec',
        textAlign:'center',
        // padding:'5px'
        paddingLeft:'5px',
        paddingRight:'5px',
        paddingTop:'5px'
    },
    statusCaption:{
        width: '62px',
        height: '16px',
        // fontFamily: 'Roboto',
        fontSize: '12px',
        // fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.4px',
        color: '#c8372d'
    },
    statusCaption1:{
        width: '62px',
        height: '16px',
        // fontFamily: 'Roboto',
        fontSize: '12px',
        // fontWeight: 'bold',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '1.33',
        letterSpacing: '0.4px',
        color: '#37833b'
    },
    modal_header: {
        backgroundColor : '#33afb0',
        color:'#ffffff'
    },

    tabs : {
        root: {
            borderBottom: '1px solid #999999',
            backgroundColor : '#33afb0'
        },
        indicator: {
            color: '#f50057',
            backgroundColor: '#f50057',
        },
    },

    tab : {
        root: {
            textTransform: 'none',
            minWidth: '180px',
            fontSize: '14px',
            fontWeight: 500,
            letterSpacing: '0.75px',
            backgroundColor: '#33afb0',
            borderRadius: '4px',
            color : '#f8f9fa',
            /*'&:hover': {
                backgroundColor: 'rgba(205, 172, 207, 0.5)',
                color: '#533278'
            },*/
            '&$selected': {
                color: '#fff',
                backgroundColor: '#33afb0',
                borderBottom: '1px solid #f50057',
            },
            '&:focus': {
                color: '#fff',
                outline:'none'
            },
        },
        selected: {},
    },
    dateLabel:{
        '&:hover':{
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        }
    }


}